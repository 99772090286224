import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { addMinutes, differenceInMinutes, endOfDay, endOfMonth, endOfWeek, isFuture, isPast, isSameMonth, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { firstValueFrom, Observable } from 'rxjs';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { LessonSessionDTO, SurveyLessonDTO } from 'src/app/models/dto/lessonSessionDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { EventDialogData } from 'src/app/models/eventDialogData';
import { CalendarService } from 'src/app/services/calendar.service';
import { UntypedFormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { RoomDTO } from 'src/app/models/dto/roomDTO';
import { MasterService } from 'src/app/services/master.service';
import { MasterDTO } from 'src/app/models/dto/masterDTO';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { AddSurveyPopUpComponent } from 'src/app/survey/add-survey-pop-up/add-survey-pop-up.component';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { AuthService } from 'src/app/services/auth.service';
import { ResDTO } from 'src/app/models/dto/resDTO';
import { SurveyService } from 'src/app/services/survey.service';
import { SelectionMode, SelectionService } from 'src/app/services/selection.service';
import { AppLessonPopUpComponent } from '../lessons/app-lesson-pop-up/app-lesson-pop-up.component';
import { AppConferencePopUpComponent } from '../conferences/app-conference-pop-up/app-conference-pop-up.component';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { ConferenceService } from 'src/app/services/conference.service';
import { ClassroomService } from 'src/app/services/classroom.service';
import { ConferenceWebinarPopupComponent } from '../webinars/conference-webinar-popup/conference-webinar-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { ConferencePresenterRole } from 'src/app/models/conference-session/conferencePresenterRole';

const enableColor: any = {
    primary: 'rgba(255,99,132,1)',
    secondary: 'rgba(255, 99, 132, 0.2)'
};
const disableColor: { primary: string, secondary: string } = {
    primary: 'rgba(54, 162, 235, 1)',
    secondary: 'rgba(54, 162, 235, 0.2)'
};
const canceledColor: { primary: string, secondary: string } = {
    primary: 'rgba(255, 206, 86, 1)',
    secondary: 'rgba(255, 206, 86, 0.2)'
};
const firstColor: { primary: string, secondary: string } = {
    primary: 'rgba(75, 192, 192, 1)',
    secondary: 'rgba(75, 192, 192, 0.2)'
};
const secondColor: { primary: string, secondary: string } = {
    primary: 'rgba(153, 102, 255, 1)',
    secondary: 'rgba(153, 102, 255, 0.2)'
};
const thirdColor: { primary: string, secondary: string } = {
    primary: 'rgba(255, 159, 64, 1)',
    secondary: 'rgba(255, 159, 64, 0.2)'
};
const exerciseColor: { primary: string, secondary: string } = {
    primary: 'rgba(255, 165, 0, 1)',  // Orange
    secondary: 'rgba(255, 165, 0, 0.2)' // Light orange
};

const colorIndex: { primary: string, secondary: string }[] = [firstColor, secondColor, thirdColor, enableColor, disableColor, canceledColor];

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

    @ViewChild('openAddEvent', { static: true }) addEventMenu: MatMenuTrigger;
    @ViewChild('openEditDelete', { static: true }) editAndDeleteMenu: MatMenuTrigger;
    @ViewChild('openOpenDelete', { static: true }) openAndDeleteMenu: MatMenuTrigger;
    @ViewChild('openOnlyOpenMenu', { static: true }) openOnlyOpenMenu: MatMenuTrigger;
    @ViewChild('openCopyMenu', { static: true }) copyMenu: MatMenuTrigger;

    @Input()
    get page(): string { return this._page; }
    set page(value: string) {
        this._page = value ? value : 'calendar';
    }

    @Input()
    get master(): MasterDTO { return this._master; }
    set master(value: MasterDTO) {
        this._master = value;
    }

    @Input()
    set updateEvents(value: boolean) {
        this.fetchEvents();
    }

    @Input()
    teachers: UserDTO[] = [];

    @Input()
    disableClickMenu: boolean = false;

    @Output()
    allRooms: EventEmitter<RoomDTO[]> = new EventEmitter();

    @Output()
    lessonAdded: EventEmitter<boolean> = new EventEmitter();

    @Output()
    lessonEdited: EventEmitter<boolean> = new EventEmitter();

    @Output()
    lessonDeleted: EventEmitter<boolean> = new EventEmitter();

    private _page: string = 'calendar';
    private _master: MasterDTO;

    contextMenuPosition = { x: '0px', y: '0px' };

    currentUser: User;

    view: CalendarView = CalendarView.Month;
    viewDate: Date = new Date();
    events$: Observable<CalendarEvent<LessonSessionDTO>[]>;
    customers: CustomerDTO[] = [];
    classrooms: ClassroomDTO[] = [];
    classroomsForMe: ClassroomDTO[] = [];
    rooms: RoomDTO[] = [];

    customer: UntypedFormControl = new UntypedFormControl(0);
    classroom: UntypedFormControl = new UntypedFormControl(0);
    teacher: UntypedFormControl = new UntypedFormControl(0);
    room: UntypedFormControl = new UntypedFormControl(0);
    lessonType: UntypedFormControl = new UntypedFormControl(0);

    copyLesson: LessonSessionDTO;
    copyConference: ConferenceDTO;

    get createLesson(): boolean { return (this.isCalendar() && this.isTeacher()) || (this.isMaster() && this.isMasterAuthor()); }
    get createSurvey(): boolean { return (this.isCalendar() && this.isTeacher()) || (this.isMaster() && this.isMasterAuthor()); }
    get createConference(): boolean { return (this.isCalendar() && (this.isTutor() || this.isTeacher() || this.isSpeaker())) || (this.isMaster() && this.isMasterAuthor()); }
    get createWebinar(): boolean { return this.isCalendar() && this.isTutor(); }
    get pasteItem(): boolean { return this.copyLesson != undefined || this.copyConference != undefined; }

    constructor(private auth: AuthService,
                private calendar: CalendarService,
                private conferenceService: ConferenceService,
                private masterService: MasterService,
                private selectionService: SelectionService,
                private surveyService: SurveyService,
                private classroomService: ClassroomService,
                private router: Router,
                private dialog: MatDialog,
                private snackBar: MatSnackBar,
                private translate: TranslateService,
                private darkService: DarkThemeService) { }

    ngOnInit(): void {
        this.currentUser = this.auth.getCurrentUser();

        if (this.isCalendar()) {
            this.fetchCustomer();
            this.fetchTeacher(this.currentUser.idCustomer);
            this.fetchClassRoom();
        }
        
        this.fetchRooms();
        this.fetchEvents();
    }

    fetchRooms() {
        if (this.isStudent() || this.isSpeaker())
            return;

        this.calendar
            .getRoomsForAll()
            .subscribe(
                output => {
                    this.rooms = output;
                    this.allRooms.emit(this.rooms);
                });
    }

    /**
     * Retrive Custumer List
     * */
    fetchCustomer() {
        if (!this.isAdmin())
            return;

        this.customer.setValue(0);
        this.customers = [];

        this.calendar
            .getCustomerForAdmin()
            .subscribe(output => this.customers = output);
    }

    /**
     * Retrive standard classroom List
     * */
    fetchClassRoom() {
        if (this.isStudent() || this.isSpeaker())
            return;

        this.classroomService
            .getClassrooms()
            .subscribe(output => this.classrooms = output as ClassroomDTO[]);
    }

    /**
     * Retrive teacher list.
     * @param roomId id class
     * @param userOrCustomerId id user or custumer
     */
    fetchTeacher(userOrCustomerId: number) {
        if (this.isStudent() || this.isSpeaker())
            return;

        this.calendar
            .getTeachersForAll(userOrCustomerId)
            .subscribe(output => this.teachers = output);
    }

    fetchEvents(): void {
        let startDate = this.getStart(this.viewDate, this.view);
        let endDate = this.getEnd(this.viewDate, this.view);
    
        if (this.view === 'month') {
            startDate = this.getStart(startDate, 'week');
            endDate = this.getEnd(endDate, 'week');
        }
    
        let output: Observable<LessonSessionDTO[]>;
    
        if (this.isCalendar()) {
            output = this.calendar.getLessons(
                startDate,
                endDate,
                this.classroom.value === 0 ? undefined : this.classroom.value,
                this.teacher.value === 0 ? undefined : this.teacher.value,
                this.room.value === 0 ? undefined : this.room.value,
                this.lessonType.value === 0 ? undefined : this.lessonType.value,
                this.isCustomerAdmin() || this.isTeacher() || this.isTutor() ? this.currentUser.idCustomer :
                this.isAdmin() ? this.customer.value : undefined,
                true
            );
        }
    
        if (this.isMaster()) {
            output = this.masterService.getLessonsOfMaster(
                this.master.id,
                this.teacher.value === 0 ? undefined : this.teacher.value,
                startDate,
                endDate
            );
        }
    
        this.events$ = output.pipe(
            map<LessonSessionDTO[], CalendarEvent[]>(res => {
                if (this.lessonType.value !== 0) {
                    res = res.filter(l => l.state === this.lessonType.value);
                }
    
                if (this.room.value !== 0) {
                    res = res.filter(l => l.roomId === this.room.value);
                }
    
                return res.map(lesson => {
                    lesson.startPlanned = new Date(lesson.startPlanned);
                    lesson.endPlanned = new Date(lesson.endPlanned);
    
                    const isExercise = lesson.typeId === 9; // Assuming 9 is the typeId for Exercise
    
                    return {
                        title: isExercise 
                            ? `<div class="calendar-lesson-title"><strong>Exercise - ${lesson.name} Lesson</strong></div>` 
                            : `<div class="calendar-lesson-title"><strong>${lesson.name}</strong></div>` +
                              (
                                  this.isConference(lesson) ? 
                                  this.conferenceInfo(lesson) :
                                  (
                                      `<div class="calendar-lesson-description">${this.translate.instant('Teacher')}: ${lesson.teacher.name} ${lesson.teacher.surname}</div>` +
                                      `<div class="calendar-lesson-description">${this.translate.instant('Room')}: ${lesson.room?.name ?? this.translate.instant('Virtual room')}</div>`
                                  )
                              ) +
                              (lesson.description ?? ''),
                        start: lesson.startPlanned,
                        end: lesson.endPlanned,
                        resizable: {
                            beforeStart: false,
                            afterEnd: false
                        },
                        color: isExercise 
                            ? { primary: 'orange', secondary: 'rgba(255,165,0,0.2)' } 
                            : colorIndex[lesson.teacherId % 6],
                        draggable: false,
                        meta: lesson,
                        cssClass: 'event-class'
                    };
                });
            })
        );
    }
    

    conferenceInfo(lesson: LessonSessionDTO) {

        if (lesson.conference.idMode === 3) {

            let moderator = lesson.conference?.conferencePresenter?.find(p => p.role === ConferencePresenterRole.Moderator)?.presenter;
            let presenters = lesson.conference?.conferencePresenter?.filter(r => r.role === ConferencePresenterRole.Presenter)?.map(cp => `${cp.presenter.name} ${cp.presenter.surname}`) ?? [''];

            return `<div class="calendar-lesson-description">${this.translate.instant('Moderated by')}: ${moderator?.name} ${moderator?.surname}</div>`
                 + `<div class="calendar-lesson-description">${this.translate.instant('Presented by')}: ${presenters.join(', ')}</div>`;

        }

        let presenters = lesson.conference?.conferencePresenter?.map(cp => `${cp.presenter.name} ${cp.presenter.surname}`) ?? [''];

        return `<div class="calendar-lesson-description">${this.translate.instant('Organized by')}: ${lesson.teacher.name} ${lesson.teacher.surname}</div>`
             + `<div class="calendar-lesson-description">${this.translate.instant('Presented by')}: ${presenters.join(', ')}</div>`;

    }

    customerChange() {
        this.teacher.setValue(0);
        this.classroom.setValue(0);

        this.teachers = [];
        this.classrooms = [];
        

        if (this.customer.value !== 0) {
            this.fetchTeacher(this.customer.value);
            this.fetchClassRoom();
        }
        
        this.fetchEvents();
    }

    classroomChange() {
        //this.teacher.setValue(0);
        this.fetchEvents();
    }

    teacherChange() {
        //this.classroom.setValue(0);
        this.fetchEvents();
    }

    roomChange() {
        this.fetchEvents();
    }

    lessonTypeChange() {
        this.fetchEvents();
    }

    dayClicked({ date, events }: { date: Date, events: Array<CalendarEvent<LessonSessionDTO>> }): void {
        if (isSameMonth(date, this.viewDate)) {
            this.view = CalendarView.Week;
            this.viewDate = date;
        }
    }

    eventClicked($event: MouseEvent, item: CalendarEvent<LessonSessionDTO>): void {
        if (this.isStudent() || this.isSpeaker() || this.disableClickMenu)
            return;

        if (
            (this.isCalendar() && this.isMe(item.meta.teacherId)) ||
            (this.isMaster() && (this.isMe(item.meta.teacherId) || this.isMasterAuthor()))
           ) {

            if (isFuture(item.start) && item.meta.state === 2) 
                this.openMenu($event, this.editAndDeleteMenu, item.meta);
            else if (item.meta.stopDate)
                this.openMenu($event, this.openAndDeleteMenu, item.meta);
            else
                this.openMenu($event, this.copyMenu, item.meta);

        } else {

            if (item.meta.state === 2)
                this.snackBar.open("You cannot open or edit lessons that are not yours", undefined, { duration: 5000, verticalPosition: "top" });
            else if (item.meta.stopDate)
                this.openMenu($event, this.openOnlyOpenMenu, item.meta);
            else
                this.snackBar.open("You cannot open or edit lessons that are currently active", undefined, { duration: 5000, verticalPosition: "top" });
        }
    }

    hourSegmentClicked($event: MouseEvent, item: Date): void {
        if (
            this.disableClickMenu ||
            isPast(item) ||
            (
                !this.createLesson &&
                !this.createSurvey &&
                !this.createConference &&
                !this.pasteItem
            )
           )
           return;

        this.openMenu($event, this.addEventMenu, item);
    }

    addExecute(start: Date, data?: EventDialogData): void {

        if (!isFuture(start))
            return;

        let eventData = data ?? this.getStandardEventDialog(start);

        const dialogRef = this.dialog.open(AppLessonPopUpComponent, {
            width: '500px',
            data: { eventData, page: this.page }
        });

        dialogRef.afterClosed()
            .subscribe((result: EventDialogData) => {
           
                if (!result)
                    return;

                let lesson: LessonSessionDTO = new LessonSessionDTO();

                lesson.teacherId = this.isMaster() ? result.teacherId : this.currentUser.id;
                lesson.startPlanned = result.startData;
                lesson.endPlanned = result.endData;
                lesson.name = result.title;
                lesson.description = result.description;
                lesson.recodingPlanned = result.recordPlan;
                lesson.privateRecording = result.private;
                lesson.smilyRecording = result.smilyRec;
                lesson.roomId = result.roomId;

                if (this.isCalendar()) {
                    lesson.classId = result.classId;

                    this.calendar.postLesson(lesson)
                        .subscribe({
                            next: () => {
                                this.lessonAdded.emit(true);
                                this.fetchEvents();
                            },
                            error: err => {
                                this.snackBar.open(err.error.Message, undefined, { duration: 3000 });
                            }
                        });
                } else if (this.isMaster()) {
                    lesson.classId = this.master.idClassroom;

                    this.masterService.postLesson(this.master.id, lesson)
                        .subscribe({
                            next: () => {
                                this.lessonAdded.emit(true);
                                this.fetchEvents();
                            },
                            error: err => {
                                this.snackBar.open(err.error.Message, undefined, { duration: 3000 });
                            }
                        });
                }
        });
    }

    addSurvey(start: Date, data?: EventDialogData): void {

        if (!isFuture(start))
            return;

        let eventData = data != null ? data : this.getStandardEventDialog(start);

        eventData.type = 4;

        const dialogRef = this.dialog.open(AddSurveyPopUpComponent, {
            width: '500px',
            data: { eventData, page: this.page, dateOrTime: false }
        });

        dialogRef.afterClosed()
            .subscribe(async (result: EventDialogData) => {
            
                if (!result)
                    return;

                let surveyLesson: SurveyLessonDTO = new SurveyLessonDTO();

                if (!result.survey) {
                    surveyLesson.teacherId = this.isMaster() ? result.teacherId : this.currentUser.id;
                    surveyLesson.startPlanned = result.startData;
                    surveyLesson.endPlanned = result.endData;
                    surveyLesson.name = result.title;
                    surveyLesson.description = result.description;
                    surveyLesson.classId = result.classId;
                    surveyLesson.typeId = 4;
                    surveyLesson.survey = new SurveyDTO();
                    surveyLesson.survey.surveyJSON = '';

                    if (data?.surveyId) {
                        await firstValueFrom(this.surveyService.getSurvey(data.surveyId))
                            .then(copySurvey => surveyLesson.survey.surveyJSON = copySurvey.surveyJSON)
                            .catch(err => console.log(err));
                    }

                } else {
                    surveyLesson.teacherId = this.isMaster() ? result.teacherId : this.currentUser.id;
                    surveyLesson.startPlanned = result.startData;
                    surveyLesson.endPlanned = result.endData;
                    surveyLesson.name = result.survey.name;
                    surveyLesson.description = result.survey.description;
                    surveyLesson.classId = result.classId;
                    surveyLesson.typeId = 4;
                    surveyLesson.survey = new SurveyDTO();
                    surveyLesson.survey.surveyJSON = result.survey.surveyJSON;
                }

                if (this.isCalendar()) {
                    surveyLesson.classId = result.classId;

                    this.surveyService.AddSurveyToLesson(surveyLesson)
                        .subscribe({
                            next: (res: ResDTO) => {

                                if (res.Message)
                                    this.router.navigate(['/survey-creator', res.Message]);

                            },
                            error: err => {
                                this.snackBar.open(err.error.Message, undefined, { duration: 3000 });
                            }
                        });
                } else if (this.isMaster()) {
                    surveyLesson.classId = this.master.idClassroom;

                    this.masterService.postSurvey(this.master.id, surveyLesson)
                        .subscribe({
                            next: (res: ResDTO) => {

                                if (res.Message)
                                    this.router.navigate(['/survey-creator', res.Message]);

                            },
                            error: err => {
                                this.snackBar.open(err.error.Message, undefined, { duration: 3000 });
                            }
                        });
                }
        });
    }
    
    addWebinar(start: Date, data?: ConferenceDTO, isCopy?: boolean) {
        const dialogRef = this.dialog.open(ConferenceWebinarPopupComponent, {
            width: '600px',
            data: {
                startData: start,
                webinar: data,
                dateOrTime: false,
                isCopy: isCopy
            },
            autoFocus: false 
        });
    
        dialogRef.afterClosed().subscribe(res => {
            if (!res)
                return;

            this.lessonAdded.emit(true);
            this.fetchEvents();
        });
    }

    addPublicConference(start: Date, data?: ConferenceDTO, isCopy?: boolean): void {
        const dialogRef = this.dialog.open(AppConferencePopUpComponent, {
            width: '600px',
            data: {
                startData: start,
                conference: data,
                page: this.page,
                dateOrTime: false,
                isCopy: isCopy,
                mode: "public"
            },
            autoFocus: false 
        });
    
        dialogRef.afterClosed().subscribe(res => {
            if (!res)
                return;

            this.lessonAdded.emit(true);
            this.fetchEvents();
        });
    }

    addPrivateConference(start: Date, data?: ConferenceDTO, isCopy?: boolean): void {
        const dialogRef = this.dialog.open(AppConferencePopUpComponent, {
            width: '600px',
            data: {
                startData: start,
                conference: data,
                page: this.page,
                dateOrTime: false,
                isCopy: isCopy,
                mode: "private"
            },
            autoFocus: false 
        });
    
        dialogRef.afterClosed().subscribe(res => {
            if (!res)
                return;

            this.lessonAdded.emit(true);
            this.fetchEvents();
        });
    }

    editSurvey(item: LessonSessionDTO) {

        if (!isFuture(item.startPlanned))
            return;

        this.router.navigate(['/survey-creator', item.idSurvey]);
    }

    editElement(lesson: LessonSessionDTO) {
        if (!lesson || !isFuture(lesson.startPlanned))
            return;

        if (this.isConference(lesson)) {

            this.conferenceService.getConferenceByLessonId(lesson.id)
                .subscribe(output => {

                    const dialogRef = output.idMode === 3
                                    ? this.dialog.open(ConferenceWebinarPopupComponent, {
                                        data: { webinar: output, dateOrTime: false },
                                        width: '600px',
                                        autoFocus: false 
                                      })
                                    : this.dialog.open(AppConferencePopUpComponent, {
                                        data: { conference: output, dateOrTime: false },
                                        width: '600px',
                                        autoFocus: false 
                                      });

                    dialogRef.afterClosed()
                        .subscribe(res => {
                            if (!res)
                                return;
                    
                            this.lessonEdited.emit(true);
                            this.fetchEvents();
                        });
                });

        } else {

            let eventData: EventDialogData = {
                startData: lesson.startPlanned,
                endData: lesson.endPlanned,
                classId: lesson.classId,
                classrooms: this.classrooms,
                rooms: this.rooms,
                title: lesson.name,
                description: lesson.description,
                recordPlan: lesson.recodingPlanned,
                private: lesson.privateRecording,
                smilyRec: lesson.smilyRecording,
                roomId: lesson.roomId,
                teacherId: lesson.teacherId,
                teachers: this.teachers,
                type: lesson.typeId
            };

            const dialogRef = this.dialog.open(AppLessonPopUpComponent, {
                width: '500px',
                data: { eventData, page: this.page }
            });
    
            dialogRef.afterClosed()
                .subscribe((result: EventDialogData) => {

                    if (!result)
                        return;

                    let editedLesson: LessonSessionDTO = new LessonSessionDTO();

                    editedLesson.id = lesson.id;
                    editedLesson.teacherId = result.teacherId;
                    editedLesson.startPlanned = result.startData;
                    editedLesson.endPlanned = result.endData;
                    editedLesson.name = result.title;
                    editedLesson.description = result.description;
                    editedLesson.recodingPlanned = result.recordPlan;
                    editedLesson.privateRecording = result.private;
                    editedLesson.smilyRecording = result.smilyRec;
                    editedLesson.roomId = result.roomId;
                    editedLesson.typeId = result.type;
                    editedLesson.classId = result.classId;
                    editedLesson.idSurvey = lesson.idSurvey;
    
                    if (editedLesson.typeId === 1 || editedLesson.typeId === 4) {
                        this.calendar.editLesson(editedLesson)
                            .subscribe({
                                next: () => {
                                    this.lessonEdited.emit(true);
                                    this.fetchEvents();
                                },
                                error: err => {
                                    this.snackBar.open(err.error.Message, undefined, { duration: 3000 });
                                }
                            });
                    } else if (editedLesson.typeId === 3) {
                        this.masterService.putLesson(editedLesson.id, editedLesson)
                            .subscribe({
                                next: () => {
                                    this.lessonEdited.emit(true);
                                    this.fetchEvents();
                                },
                                error: err => {
                                    this.snackBar.open(err.error.Message, undefined, { duration: 3000 });
                                }
                            });
                    }
            }); 

        }
    }

    deleteElement(lesson: LessonSessionDTO) {
        if (!lesson)
            return;

        if (this.isConference(lesson)) {

            this.conferenceService.getConferenceByLessonId(lesson.id)
                .subscribe({
                    next: conference => {

                        this.conferenceService.deleteConference(conference.id)
                            .subscribe({
                                next: () => {
                                    this.snackBar.open('Conference deleted', undefined, { duration: 3000 });
                                    this.lessonDeleted.emit(true);
                                    this.fetchEvents();
                                },
                                error: error => {
                                    this.snackBar.open('Error deleting conference', undefined, { duration: 3000 });
                                }
                            });

                    },
                    error: error => {
                        console.error(error);
                        this.snackBar.open(error.Message, undefined, { duration: 5000 });
                    }
                });

        } else {

            this.calendar.deleteLesson(lesson.id).subscribe({
                next: () => {
                    this.lessonDeleted.emit(true);
                    this.fetchEvents();
                },
                error: err => console.log(err.message, err.errorStack)
            });

        }
    }

    openElement(lesson: LessonSessionDTO) {
        if (!lesson)
            return;

        if (this.isConference(lesson))
            this.router.navigate(['/conference-report', lesson.id]);
        else if (lesson.state === 2)
            this.snackBar.open('No users were connected to the lesson', undefined, { duration: 3000 });
        else
            this.router.navigate(['/calendar', lesson.id]);
    }

    copyElement(lesson: LessonSessionDTO) {
        if (!lesson)
            return;

        this.copyLesson = undefined;
        this.copyConference = undefined;

        if (this.isConference(lesson))
            this.conferenceService.getConferenceByLessonId(lesson.id)
                .subscribe(output => this.copyConference = output);
        else
            this.copyLesson = lesson;
    }

    pasteElement(start: Date) {
        if (!isFuture(start))
            return;

        if (this.copyConference) {

            this.copyConference.idMode === 3 ?
            this.addWebinar(start, this.copyConference, true) :
            this.copyConference.isPublic === 1 ?
            this.addPublicConference(start, this.copyConference, true) :
            this.addPrivateConference(start, this.copyConference, true);

        } else {

            let eventData: EventDialogData = {
                startData: start,
                endData: addMinutes(start, differenceInMinutes(this.copyLesson.endPlanned, this.copyLesson.startPlanned)),
                classId: this.copyLesson.classId,
                classrooms: this.classrooms,
                rooms: this.rooms,
                title: this.copyLesson.name,
                description: this.copyLesson.description,
                recordPlan: this.copyLesson.recodingPlanned,
                private: this.copyLesson.privateRecording,
                smilyRec: this.copyLesson.smilyRecording,
                roomId: this.copyLesson.roomId,
                teacherId: this.copyLesson.teacherId,
                teachers: this.teachers,
                type: this.copyLesson.typeId,
                surveyId: this.copyLesson.idSurvey
            };
    
            eventData.type === 4 ? this.addSurvey(start, eventData) : this.addExecute(start, eventData);

        }

        this.copyLesson = undefined;
        this.copyConference = undefined;
    }

    addContents(lessonId: number) {
        this.selectionService.startSelection(lessonId, SelectionMode.lessonContents);
    }

    isLesson(lesson: LessonSessionDTO) {
        return lesson.typeId === 1 || lesson.typeId === 3;
    }

    isSurvey(lesson: LessonSessionDTO) {
        return lesson.typeId === 4; // || lesson.typeId === 5;
    }

    isConference(lesson: LessonSessionDTO) {
        return (lesson.typeId === 6 || lesson.typeId === 8);
            //&& (lesson.conference?.idMode === 1 || lesson.conference?.idMode === 2 || lesson.conference?.idMode === 3);
    }

    isAdmin() {
        return this.currentUser.isAdmin;
    }

    isCustomerAdmin() {
        return this.currentUser.isCustomerAdmin;
    }

    isTutor() {
        return this.currentUser.isTutor;
    }

    isTeacher() {
        return this.currentUser.isTeacher;
    }

    isSpeaker() {
        return this.currentUser.isSpeaker;
    }

    isStudent() {
        return this.currentUser.isStudent;
    }

    /**
     * Controlla se id passato e' quello dell'utente loggato (l'unico che puo' modificare gli eventi)
     * @param userId
     */
    isMe(userId: number): boolean {
        return this.currentUser.id === userId;
    }

    isMasterAuthor(): boolean {
        return this.currentUser.id === this.master.idAuthor;
    }

    isCalendar(): boolean {
        return this.page === "calendar";
    }

    isMaster(): boolean {
        return this.page === "master";
    }

    isDark() {
        return this.darkService.isSetDark;
    }

    private getStart(date: Date, view: string) {
        return {
            month: startOfMonth(date),
            week: startOfWeek(date),
            day: startOfDay(date)
        }[view];
    }

    private getEnd(date: Date, view: string) {
        return {
            month: endOfMonth(date),
            week: endOfWeek(date),
            day: endOfDay(date)
        }[view];
    }

    private openMenu($event: MouseEvent, menu: MatMenuTrigger, data: any) {
        this.contextMenuPosition.x = $event.clientX + 'px';
        this.contextMenuPosition.y = $event.clientY + 'px';
        menu.menuData = { item: data };
        menu.menu.focusFirstItem('mouse');
        menu.openMenu();
    }

    private getStandardEventDialog(start: Date) {
        return <EventDialogData> {
            startData: start,
            classId: this.isCalendar() ? this.classrooms[0].id : null,
            classrooms: this.classrooms,
            rooms: this.rooms,
            teacherId: this.isMaster() ? this.teachers[0].id : null,
            teachers: this.teachers,
            type: this.isCalendar() ? 1 : 3
        };
    }

}
