import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MasterDTO } from 'src/app/models/dto/masterDTO';
import { Helper } from 'src/app/helpers/helper';
import { MasterEdit } from 'src/app/models/masterEdit';
import { LessonSessionDTO, SurveyLessonDTO } from 'src/app/models/dto/lessonSessionDTO';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { SubscriptionDTO } from 'src/app/models/dto/subscriptionDTO';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { TranslationService } from './translation.service';
import { SubscriptionUserDTO } from 'src/app/models/dto/subscriptionUserDTO';
import { ConferencePost } from '../models/conferencePost';
import { MasterContentEdit } from '../models/masterContentEdit';
import { MasterContentDTO } from '../models/dto/masterContentDTO';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
  private bckEndUrlSubscriptions = `${this.bckEndUrlBaseUrl}/subscription`;
  private bckEndUrlMasters = `${this.bckEndUrlBaseUrl}/masters`;
  private bckEndUrlMaster = `${this.bckEndUrlMasters}/master`;
  private bckEndUrlLesson = `${this.bckEndUrlMasters}/lesson`;
  private bckEndUrlSurvey = `${this.bckEndUrlMasters}/lesson`;

  constructor(private http: HttpClient, private translationService: TranslationService) { }

  public getMasters (userAuthenticated: boolean): Observable<MasterDTO[]> {
    return this.http.get<MasterDTO[]>(`${this.bckEndUrlMasters}/all/${this.translationService.currentLang.lang}`, { headers: userAuthenticated ? Helper.getAuthHeader() : null });
  }

  public getUserMaster (): Observable<MasterDTO[]> {
    return this.http.get<MasterDTO[]>(`${this.bckEndUrlMasters}/user/${this.translationService.currentLang.lang}`, { headers: Helper.getAuthHeader() });
  }

  public getMasterTemplates (): Observable<MasterDTO[]> {
    return this.http.get<MasterDTO[]>(`${this.bckEndUrlMasters}/templates/${this.translationService.currentLang.lang}`, { headers: Helper.getAuthHeader() });
  }

  public getDerivedMastersOfTemplate (masterId: number): Observable<MasterDTO[]> {
    return this.http.get<MasterDTO[]>(`${this.bckEndUrlMasters}/derived/${masterId}/${this.translationService.currentLang.lang}`, { headers: Helper.getAuthHeader() });
  }

  public getMaster (idMaster: number, userAuthenticated: boolean): Observable<MasterDTO> {
    return this.http.get<MasterDTO>(`${this.bckEndUrlMaster}/${idMaster}/${this.translationService.currentLang.lang}`, { headers: userAuthenticated ? Helper.getAuthHeader() : null });
  }

  /**
   * Restituisce le lezioni di un master, senza fornire `startDate` e `endDate` verranno restituite tutte le lezioni del master
   * @param startDate Data inizio calendario
   * @param endDate Data fine calendario
   * @param teacherId Id dell' insegnate, facoltativo
   */
  public getLessonsOfMaster (idMaster: number, teacherId: number = undefined, startDate: Date = undefined, endDate: Date = undefined): Observable<LessonSessionDTO[]> {
    var param: HttpParams = new HttpParams();

    if (startDate != undefined && endDate != undefined) {
      param = new HttpParams()
        .set("startDate", startDate.toISOString())
        .set("endDate", endDate.toISOString());
    }

    if (teacherId != undefined)
      param.set("teacherId", teacherId.toString());

    return this.http.get<LessonSessionDTO[]>(`${this.bckEndUrlMasters}/lessons/${idMaster}`, { headers: Helper.getAuthHeader(), params: param });
  }

  public getParticipantsOfMaster (idMaster: number): Observable<ClassroomDTO> {
    return this.http.get<ClassroomDTO>(`${this.bckEndUrlMaster}/${idMaster}`, { headers: Helper.getAuthHeader() });
  }

  public getCustomersOfMaster (): Observable<CustomerDTO[]> {
    return this.http.get<CustomerDTO[]>(`${this.bckEndUrlMasters}/customers`, { headers: Helper.getAuthHeader() });
  }

  public joinMaster (idMaster: number) {
    return this.http.post(`${this.bckEndUrlMaster}/${idMaster}/join`, null, { headers: Helper.getAuthHeader() });
  }

  public unjoinMaster (idMaster: number) {
    return this.http.delete(`${this.bckEndUrlMaster}/${idMaster}/unjoin`, { headers: Helper.getAuthHeader() });
  }

  public postMaster (master: MasterEdit) {
    master.Language = this.translationService.currentLang.lang;

    return this.http.post(`${this.bckEndUrlMaster}`, master, { headers: Helper.getAuthHeader() });
  }

  public postMasterTemplate (master: MasterEdit) {
    master.Language = this.translationService.currentLang.lang;

    return this.http.post(`${this.bckEndUrlMasters}/template`, master, { headers: Helper.getAuthHeader() });
  }

  public putMaster (id: number, master: MasterEdit) {
    master.Language = this.translationService.currentLang.lang;

    return this.http.put(`${this.bckEndUrlMaster}/${id}`, master, { headers: Helper.getAuthHeader() })
  }

  public setMasterState (state: number, masterId: number) {
    return this.http.put(`${this.bckEndUrlMaster}/${masterId}/state/${state}`, {}, { headers: Helper.getAuthHeader() });
  }

  public addTeachers (idMaster: number, teachers: number[]) {
    return this.http.post(`${this.bckEndUrlMaster}/${idMaster}/teachers`, teachers, { headers: Helper.getAuthHeader() });
  }

  public postLesson (idMaster: number, lesson: LessonSessionDTO) {
    return this.http.post(`${this.bckEndUrlLesson}/${idMaster}`, lesson, { headers: Helper.getAuthHeader() });
  }

  public putLesson (idLesson: number, lesson: LessonSessionDTO) {
    return this.http.put(`${this.bckEndUrlLesson}/${idLesson}`, lesson, { headers: Helper.getAuthHeader() });
  }

  public addSubscription (idMaster: number, subscription: SubscriptionDTO) {
    return this.http.post(`${this.bckEndUrlSubscriptions}/master/${idMaster}`, subscription, { headers: Helper.getAuthHeader() });
  }
  
  public postSurvey (idMaster: number, lesson: SurveyLessonDTO) {
    return this.http.post(`${this.bckEndUrlSurvey}/${idMaster}`, lesson, { headers: Helper.getAuthHeader() });
  }

  public putSurvey (lesson: SurveyLessonDTO) {
    return this.http.post(`${this.bckEndUrlSurvey}`, lesson, { headers: Helper.getAuthHeader() });
  }

  public putSubscription (idMaster: number, subscription: SubscriptionDTO) {
    return this.http.put(`${this.bckEndUrlSubscriptions}/master/${idMaster}`, subscription, { headers: Helper.getAuthHeader() });
  }

  public getMasterContent(idMaster: number, idMasterContent: number): Observable<MasterContentDTO> {
    return this.http.get<MasterContentDTO>(`${this.bckEndUrlMaster}/${idMaster}/content/${idMasterContent}/${this.translationService.currentLang.lang}`, { headers: Helper.getAuthHeader() });
  }

  public postMasterContent (idMaster: number, content: MasterContentEdit) {
    content.language = this.translationService.currentLang.lang;

    return this.http.post(`${this.bckEndUrlMaster}/${idMaster}/media`, content, { headers: Helper.getAuthHeader() });
  }

  public putMasterContent (idMaster: number, idMasterContent: number, content: MasterContentEdit) {
    content.language = this.translationService.currentLang.lang;

    return this.http.put(`${this.bckEndUrlMaster}/${idMaster}/media/${idMasterContent}`, content, { headers: Helper.getAuthHeader() });
  }

  public deleteMasterContent (idMaster: number, idMasterContent: number) {
    return this.http.delete(`${this.bckEndUrlMaster}/${idMaster}/media/${idMasterContent}`, { headers: Helper.getAuthHeader() });
  }

  /*
  public addPreview (idMaster: number, preview: MasterEdit) {
    return this.http.post(`${this.bckEndUrlMaster}/${idMaster}/preview`, preview, { headers: Helper.getAuthHeader() });
  }

  public deletePreview (idMaster: number, preview: number) {
    return this.http.delete(`${this.bckEndUrlMaster}/${idMaster}/preview/${preview}`, { headers: Helper.getAuthHeader() });
  }
  */

  public buyMaster(subId: number, userId?: number) {
    let options: any = { headers: Helper.getAuthHeader() };

    if (userId != null)
      options.params = new HttpParams()
                        .set('targetUser', String(userId));

    return this.http.post(`${this.bckEndUrlSubscriptions}/paid/master/${subId}`, {}, options);
  }

  public userHasBoughtSubscriptionWithOrigin(idSubscription: number): Observable<SubscriptionUserDTO> {
    return this.http.get<SubscriptionUserDTO>(`${this.bckEndUrlSubscriptions}/checkOriginSubs/${idSubscription}`, { headers: Helper.getAuthHeader() });
  }

  public updateMasterVisibility(masterId: number, visibility: number) {
    return this.http.get(`${this.bckEndUrlMaster}/${masterId}/visibility/${visibility}`, { headers: Helper.getAuthHeader() });
  }

  public postMasterConference(masterId: number, newConference: ConferencePost) {
    return this.http.post(`${this.bckEndUrlMasters}/conference/${masterId}`, newConference, { headers: Helper.getAuthHeader() });
  }

  public putMasterConference(lessonId: number, conference: ConferencePost) {
    return this.http.put(`${this.bckEndUrlMasters}/conference/${lessonId}`, conference, { headers: Helper.getAuthHeader() });
  }

  public deleteMaster(masterId: number) {
    return this.http.delete(`${this.bckEndUrlMaster}/${masterId}`, { headers: Helper.getAuthHeader() });
  }

  public getLatestMasterOfTemplate(templateId: number, userId?: number) {
    let params = new HttpParams();

    if (userId != undefined)
      params = params.append('userId', userId);

    return this.http.get<MasterDTO>(`${this.bckEndUrlMasters}/derived/${templateId}/latest/${this.translationService.currentLang.lang}`, { headers: Helper.getAuthHeader(), params: params });
  }

}
