import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExerciseDTO } from '../models/dto/exerciseDTO';
import { ClassroomDTO } from '../models/dto/classroomDTO';
import { Helper } from '../helpers/helper';
import { ExerciseSessionDTO } from '../models/dto/exerciseSessionDTO';

@Injectable({
  providedIn: 'root'
})
export class ExerciseService {
  private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
  private bckEndUrlExercises = `${this.bckEndUrlBaseUrl}/exercise`;
  private bckEndUrlClassrooms = `${this.bckEndUrlBaseUrl}/classrooms`;

  constructor(private http: HttpClient) {}

  public getExercises(): Observable<ExerciseDTO[]> {
    return this.http.get<ExerciseDTO[]>(`${this.bckEndUrlExercises}/list/{lang}`, { headers: Helper.getAuthHeader() })
  }

  public getExerciseById(id: number): Observable<ExerciseDTO> {
    return this.http.get<ExerciseDTO>(`${this.bckEndUrlExercises}/${id}`, { headers: Helper.getAuthHeader() });
  }

  public addExercise(exercise: ExerciseDTO): Observable<ExerciseDTO> {
    return this.http.post<ExerciseDTO>(`${this.bckEndUrlExercises}/create`, exercise, { headers: Helper.getAuthHeader() });
  }

  public editExercise(exercise: ExerciseDTO): Observable<ExerciseDTO> {
    return this.http.put<ExerciseDTO>(`${this.bckEndUrlExercises}/edit`, exercise, { headers: Helper.getAuthHeader() });
  }

  public deleteExercise(id: number): Observable<any> {
    return this.http.delete(`${this.bckEndUrlExercises}/delete/${id}`, { headers: Helper.getAuthHeader() });
  }

  public getClassrooms(): Observable<ClassroomDTO[]> {
    return this.http.get<ClassroomDTO[]>(this.bckEndUrlClassrooms, { headers: Helper.getAuthHeader() });
  }

  public postExerciseResults(exerciseResult: ExerciseSessionDTO): Observable<any> {
    return this.http.post<any>(`${this.bckEndUrlExercises}/post-exercise-results`, exerciseResult, { headers: Helper.getAuthHeader() });
  }

  getExerciseResults(exerciseId: number): Observable<ExerciseSessionDTO[]> {
    return this.http.get<ExerciseSessionDTO[]>(`${this.bckEndUrlExercises}/get-exercise-results/${exerciseId}`, {
      headers: Helper.getAuthHeader(),
    });
  }

  getExerciseDetails(exerciseId: number, userId: number): Observable<any> {
    return this.http.get<any>(`${this.bckEndUrlExercises}/details/${exerciseId}/${userId}`, {
      headers: Helper.getAuthHeader(),
    });
  }   
  
}
