import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExerciseService } from 'src/app/services/exercise.service';
import { Location } from '@angular/common';
import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { ExerciseDTO } from 'src/app/models/dto/exerciseDTO';
import { ExerciseSessionDTO } from 'src/app/models/dto/exerciseSessionDTO';
Chart.register(zoomPlugin);

@Component({
  selector: 'app-exercise-details',
  templateUrl: './exercise-details.component.html',
  styleUrls: ['./exercise-details.component.scss']
})
export class ExerciseDetailsComponent implements OnInit {
  exerciseDetails: {
    exercise?: ExerciseDTO;
    session?: ExerciseSessionDTO;
  } | null = null;

  loading: boolean = true;
  error: string | null = null;
  chartData: { labels: string[], speed: number[], torque: number[] } | null = null;
  grade: string = '';
  addingGrade: boolean;

  constructor(
    private route: ActivatedRoute,
    private exerciseService: ExerciseService,
    private location: Location
  ) {}

  ngOnInit(): void {
    const exerciseId = this.route.snapshot.queryParamMap.get('exerciseId');
    const userId = this.route.snapshot.queryParamMap.get('userId');

    if (exerciseId && userId) {
      this.exerciseService.getExerciseDetails(+exerciseId, +userId).subscribe({
        next: (details) => {
          console.log('Received details:', details);
          console.log('RGB Video Path:', details?.session?.rgbVideoPath);
          this.exerciseDetails = details;
          this.loading = false;

          const speedPath = details.session?.mmSpeedPath;
          const torquePath = details.session?.mmTorquePath;
          if (speedPath && torquePath) {
            this.fetchChartData(speedPath, torquePath);
          }
        },
        error: (err) => {
          console.error('Error fetching exercise details:', err);
          this.error = 'Failed to load exercise details. Please try again later.';
          this.loading = false;
        }
      });
    } else {
      this.error = 'Invalid exercise or user ID.';
      this.loading = false;
    }
  }

  goBack(): void {
    this.location.back();
  }

  fetchChartData(speedPath: string, torquePath: string): void {
    Promise.all([
      fetch(speedPath).then((response) => response.json()),
      fetch(torquePath).then((response) => response.json())
    ])
      .then(([speedData, torqueData]) => {
        // Parse data into chart format
        const labels = speedData.map((item: any) => new Date(item.Time).toLocaleTimeString());
        const speedValues = speedData.map((item: any) => item.Speed);
        const torqueValues = torqueData.map((item: any) => item.Torque);

        this.chartData = {
          labels: labels,
          speed: speedValues,
          torque: torqueValues
        };

        this.renderChart();
      })
      .catch((error) => {
        console.error('Error fetching chart data:', error);
      });
  }

  renderChart(): void {
    const ctx = document.getElementById('speedTorqueChart') as HTMLCanvasElement;
  
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.chartData.labels,
        datasets: [
          {
            label: 'Speed (RPM)',
            data: this.chartData.speed,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            yAxisID: 'ySpeed',
            fill: true,
            tension: 0.3,
          },
          {
            label: 'Torque (Nm)',
            data: this.chartData.torque,
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            yAxisID: 'yTorque',
            fill: true,
            tension: 0.3,
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false, // Allow manual control of aspect ratio
        plugins: {
          legend: {
            display: true,
            position: 'top'
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true // Enable zooming with mouse wheel
              },
              pinch: {
                enabled: true // Enable pinch zooming
              },
              mode: 'xy' // Enable zooming on both axes
            },
            pan: {
              enabled: true, // Enable panning
              mode: 'xy' // Enable panning on both axes
            }
          }
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Time'
            },
            ticks: {
              maxRotation: 45,
              minRotation: 45
            }
          },
          ySpeed: {
            position: 'left',
            title: {
              display: true,
              text: 'Speed (RPM)'
            },
            grid: {
              drawOnChartArea: true
            },
            ticks: {
              stepSize: 5000
            }
          },
          yTorque: {
            position: 'right',
            title: {
              display: true,
              text: 'Torque (Nm)'
            },
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              stepSize: 1
            }
          }
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10
          }
        }
      }
    });
  }  

  openPath(path: string): void {
    if (path) {
      window.open(path, '_blank');
    } else {
      console.error('Path is invalid or empty.');
    }
  }

  submitGrade(): void {
    console.log('Grade submitted:', this.grade); // Replace with actual save logic
    this.addingGrade = false;
    this.grade = '';
  }
}