<div fxLayout="column" class="component-main">
  <div fxLayoutAlign="center center" fxLayout="column" style="align-self: center;">
    <h2>{{ 'Students status of virtual classroom' | role | translate }} <span style="color: red">{{classroomName}}</span></h2> 
    <h4 *ngIf="authorName != null" style="margin-top: 0px">
      {{ 'By' | translate }}
      <span [style.color]="darkService.isSetDark ? '#00acc1' : 'firebrick'">
        {{ ' ' + authorName }}
      </span>
      {{ ' ' + ('on' | translate) + ' ' + (created | date: 'dd/MM/yyyy') }}
    </h4>
  </div>
  <div class="btn-group">
    <button mat-stroked-button (click)="goBack()" style="margin-right: 30px;">
      <mat-icon>arrow_back</mat-icon>
      {{ 'Back' | translate }}
    </button>
    <button mat-fab color="primary" (click)="updatePage()" [matTooltip]="'Refresh' | translate" matTooltipPosition="after">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div *ngIf="dataSource && dataSource.length > 0" fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="60px">
    <div fxFlex="70" fxLayout="column" fxLayoutAlign="start center" style="height: 65vh; overflow: auto;">
      <table mat-table [dataSource]="dataSource" *ngIf="dataSource && dataSource.length > 0" class="mat-elevation-z8">

        <ng-container matColumnDef="surname">
            <th mat-header-cell *matHeaderCellDef class="tableHeader" style="text-align: start;">{{ 'Surname' | translate }}</th>
            <td mat-cell *matCellDef="let element" style="text-align: start;"> {{ element.surname }} </td>
        </ng-container>
      
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="tableHeader" style="text-align: start;">{{ 'Name' | translate }}</th>
            <td mat-cell *matCellDef="let element" style="text-align: start;"> {{ element.name }} </td>
        </ng-container>

        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef class="tableHeader" style="text-align: start;">{{ 'total logged time' | translate }}<br>HH:mm</th>
          <td mat-cell *matCellDef="let element" style="text-align: start;"> {{ element.timeInSeconds ? element.timeInSeconds : '00:00' }} </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef class="tableHeader" style="text-align: start;">{{ 'Date' | translate }}</th>
          <td mat-cell *matCellDef="let element" style="text-align: start;"> 
            {{ element.endDate == "--" ? "--" : element.endDate ? (('Last' | translate) + ': ' + (element.endDate | date: 'HH:mm  dd/MM/yyyy')) : (('From' | translate) + ': ' + (element.startDate | date: 'HH:mm  dd/MM/yyyy')) }} 
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="tableHeader" style="text-align: start;">{{ 'Status' | translate }}</th>
          <td mat-cell *matCellDef="let element" style="text-align: start;">
              <mat-icon [style.color]="element.endDate ? 'red' : 'yellowgreen'" [matTooltip]="element.endDate ? 'Offline' : 'Online'">fiber_manual_record</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="info">
          <th mat-header-cell *matHeaderCellDef class="tableHeader" style="text-align: start;">Info</th>
          <td mat-cell *matCellDef="let element" style="text-align: start;">
            <button mat-icon-button (click)="userStats(element.userId, element.name, element.surname)" [disabled]="!element.startDate" [matTooltip]="'Student data' | role | translate">
              <mat-icon>
                  assessment
              </mat-icon>
            </button>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
      </table>
    </div>
    <div fxLayout="column" fxFlex="30" fxLayoutAlign="start center" fxLayoutGap="40px">
        <mat-card appearance="outlined" class="cardClass mat-elevation-z8">
          <mat-card-title style="font-size: 16px;margin:5%">
            {{'The average lesson lasted' | translate}} {{lessonAverageDuration}} {{'of the' | translate}} {{lessonAveragePlannedDuration}} {{'planned' | translate}}
          </mat-card-title>
          <mat-card-content>
              <app-spinner-container
                  class="center-item"
                  color="primary"
                  [diameter]="75"
                  [strokeWidth]="5"
                  [value]="durationSpinnerValue"
                  [displayWith]="spinnerValue">
              </app-spinner-container>
          </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined" class="cardClass mat-elevation-z8">
          <mat-card-title style="font-size: 16px;margin:5%">
            {{'The average attendance to the lessons is' | translate}} {{ lessonAverageAttendance | number: '1.0-2' }}%
          </mat-card-title>
          <mat-card-content>
              <app-spinner-container
                  class="center-item"
                  color="primary"
                  [diameter]="75"
                  [strokeWidth]="5"
                  [value]="lessonAverageAttendance"
                  [displayWith]="spinnerValue">
              </app-spinner-container>
          </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined" class="cardClass mat-elevation-z8" *ngIf="lineChartLabels && lineChartLabels.length > 0">
          <mat-card-title style="font-size: 16px;margin:5%">
            {{ 'Average attendance trend from' | translate}} {{ lineChartLabels[0] }} {{ 'toD' | translate }} {{ lineChartLabels[lineChartLabels.length-1] }}
          </mat-card-title>
          <mat-card-content>
            <div class="graph">
              <canvas baseChart width="400px" height="110px"
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [type]="lineChartType">
              </canvas>
            </div>
          </mat-card-content>
        </mat-card>
    </div>
  </div>
  <div *ngIf="dataSource && dataSource.length == 0">
    <app-error-message customClass="warning"
      [errorTitle]="'There arent any students in this virtual classroom' | role | translate"
      [errorContent]="'You can try to add students to the virtual classroom to have some results' | role | translate">
    </app-error-message>
  </div>  
</div>
