import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Helper } from "../helpers/helper";
import { ConferenceDTO } from "../models/dto/conferenceDTO";
import { JoinedLessonDTO } from "../models/dto/joinedLessonDTO";
import { TranslationService } from "./translation.service";
import { SubscriptionDTO } from "../models/dto/subscriptionDTO";
import { UserDTO } from "../models/dto/userDTO";
import { ConferencePost } from "../models/conferencePost";
import { LessonSessionDTO } from "../models/dto/lessonSessionDTO";

@Injectable({
    providedIn: 'root'
})
export class ConferenceService {
    private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
    private bckEndUrlConferences = `${this.bckEndUrlBaseUrl}/conferences`;
    private bckEndUrlSubscriptions = `${this.bckEndUrlBaseUrl}/subscription`;

    constructor(private http: HttpClient, private translationService: TranslationService) { }

    public joinConference(link: string): Observable<ConferenceDTO> {
        return this.http.get<ConferenceDTO>(`${this.bckEndUrlConferences}/conference/join/${link}`, { headers: Helper.getAuthHeader() });
    }

    public generateTokenConference(conferenceId: number, virtualRoomId?: number, preview?: boolean): Observable<any> {
        let params = new HttpParams();

        if (virtualRoomId)
            params = params.append('virtualRoomId', virtualRoomId);

        if (preview)
            params = params.append('preview', preview);

        return this.http.get(`${this.bckEndUrlConferences}/conference/token/${conferenceId}`, { params: params, headers: Helper.getAuthHeader() });
    }

    public joinConferenceStream(lessonId: number, connectionId: string, streamId: string, role: 'PUBLISHER' | 'SUBSCRIBER', position: number, prevStreamId: string = null) {
        let data = {
            connectionId: connectionId,
            streamId: streamId,
            role: role,
            position: position,
            prevStreamId: prevStreamId,
            stationId: null,
            loginType: 0
        }

        return this.http.post(`${this.bckEndUrlConferences}/conference/stream/${lessonId}`, data, { headers: Helper.getAuthHeader() });
    }

    public leaveConferenceStream(lessonId: number, connectionId: string, streamId: string) {
        let data = {
            connectionId: connectionId,
            streamId: streamId,
            role: null,
            position: 1,
            prevStreamId: null,
            stationId: null,
            loginType: 0
        }

        return this.http.put(`${this.bckEndUrlConferences}/conference/stream/${lessonId}`, data, { headers: Helper.getAuthHeader() });
    }
    
    public getConferences(mode: number[] = [], all: boolean = false): Observable<ConferenceDTO[]> {
        let params: HttpParams = new HttpParams()
            .set('all', all);

        mode.map(m => params = params.append('mode', m));

        return this.http.get<ConferenceDTO[]>(`${this.bckEndUrlConferences}/${this.translationService.currentLang.lang}`, { headers: Helper.getAuthHeader(), params: params });
    }

    public getConferencesShowcase(): Observable<ConferenceDTO[]> {
        return this.http.get<ConferenceDTO[]>(`${this.bckEndUrlConferences}/showcase/${this.translationService.currentLang.lang}`, { headers: Helper.getAuthHeader() });
    }

    public getConference(conferenceId: number): Observable<ConferenceDTO> {
        return this.http.get<ConferenceDTO>(`${this.bckEndUrlConferences}/conference/${conferenceId}/${this.translationService.currentLang.lang}`, { headers: Helper.getAuthHeader() });
    }

    public getConferenceByLessonId(lessonSessionId: number): Observable<ConferenceDTO> {
        return this.http.get<ConferenceDTO>(`${this.bckEndUrlConferences}/conference/lesson/${lessonSessionId}/${this.translationService.currentLang.lang}`, { headers: Helper.getAuthHeader() });
    }

    public getConferenceShowcase(conferenceId: number): Observable<ConferenceDTO> {
        return this.http.get<ConferenceDTO>(`${this.bckEndUrlConferences}/conference/showcase/${conferenceId}/${this.translationService.currentLang.lang}`, { headers: Helper.getAuthHeader() });
    }

    public getConferenceParticipants(conferenceId: number): Observable<UserDTO[]> {
        return this.http.get<UserDTO[]>(`${this.bckEndUrlConferences}/conference/${conferenceId}/participants`, { headers: Helper.getAuthHeader() });
    }

    public getWebinarCustomer(): Observable<ConferenceDTO[]> {
        return this.http.get<ConferenceDTO[]>(`${this.bckEndUrlConferences}/customerWebinar/${this.translationService.currentLang.lang}`, { headers: Helper.getAuthHeader() });
    }

    public getHashtags(): Observable<string[]> {
        return this.http.get<string[]>(`${this.bckEndUrlConferences}/hashtags`, { headers: Helper.getAuthHeader() });
    }

    public getConferenceRoom(idConference: number, virtualRoomId: number): Observable<LessonSessionDTO> {
        return this.http.get<LessonSessionDTO>(`${this.bckEndUrlConferences}/conference/${idConference}/room/${virtualRoomId}`, { headers: Helper.getAuthHeader() });
    }

    public createConference(conferencePost: ConferencePost) {
        return this.http.post(`${this.bckEndUrlConferences}/conference`, conferencePost, { headers: Helper.getAuthHeader() }); 
    }

    public putConference(id: number, conferencePost: ConferencePost) {
        return this.http.put(`${this.bckEndUrlConferences}/conference/${id}`, conferencePost, { headers: Helper.getAuthHeader() });
    }

    public deleteConference(id:number) {
        return this.http.delete(`${this.bckEndUrlConferences}/conference/${id}`, { headers: Helper.getAuthHeader() });
    }

    public getOnlinePresenters(): Observable<JoinedLessonDTO[]> {
        return this.http.get<JoinedLessonDTO[]>(`${this.bckEndUrlConferences}/conference/onlinePresenters/`, { headers: Helper.getAuthHeader() });
    }

    public getUsersInRoom(idConference: number, virtualRoomId: number): Observable<UserDTO[]> {
        return this.http.get<UserDTO[]>(`${this.bckEndUrlConferences}/conference/${idConference}/room/${virtualRoomId}/users`, { headers: Helper.getAuthHeader() });
    }

    public addSubscription(idConference: number, subscription: SubscriptionDTO) {
        return this.http.post(`${this.bckEndUrlSubscriptions}/conference/${idConference}`, subscription, { headers: Helper.getAuthHeader() });
    }
        
    public putSubscription(idConference: number, subscription: SubscriptionDTO) {
        return this.http.put(`${this.bckEndUrlSubscriptions}/conference/${idConference}`, subscription, { headers: Helper.getAuthHeader() });
    }

    public buyConference(subId: number, userId?: number) {
        let options: any = { headers: Helper.getAuthHeader() };

        if (userId != null)
            options.params = new HttpParams()
                                .set('targetUser', String(userId));

        return this.http.post(`${this.bckEndUrlSubscriptions}/paid/conference/${subId}`, {}, options);
    }

    public changeConferenceState(idConference: number, idState: number) {
        return this.http.put(`${this.bckEndUrlConferences}/conference/${idConference}/state/${idState}`, { }, { headers: Helper.getAuthHeader() });
    }

    public createCourse(idConference: number) {
        return this.http.post(`${this.bckEndUrlConferences}/conference/${idConference}/course`, { }, { headers: Helper.getAuthHeader() });
    }

    public createConferenceRoom(idConference: number, idVirtualRoom: number) {
        return this.http.post(`${this.bckEndUrlConferences}/conference/${idConference}/room/${idVirtualRoom}`, { }, { headers: Helper.getAuthHeader() });
    }

    public addUsersToRoom(idConference: number, virtualRoomId: number, userIds: number[]) {
        return this.http.post(`${this.bckEndUrlConferences}/conference/${idConference}/room/${virtualRoomId}/join`, { userIds: userIds }, { headers: Helper.getAuthHeader() });
    }

    public removeUserFromRoom(idConference: number, virtualRoomId: number, userIds: number[]) {
        return this.http.put(`${this.bckEndUrlConferences}/conference/${idConference}/room/${virtualRoomId}/unjoin`, { userIds: userIds }, { headers: Helper.getAuthHeader() });
    }
}
