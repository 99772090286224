<table mat-table [dataSource]="results" class="mat-elevation-z8">

  <!-- Start Date Column -->
  <ng-container matColumnDef="startDate">
    <th mat-header-cell *matHeaderCellDef> Start Date </th>
    <td mat-cell *matCellDef="let result"> {{ result.startDate | date: 'medium' }} </td>
  </ng-container>

  <!-- Stop Date Column -->
  <ng-container matColumnDef="stopDate">
    <th mat-header-cell *matHeaderCellDef> Stop Date </th>
    <td mat-cell *matCellDef="let result"> {{ result.stopDate | date: 'medium' }} </td>
  </ng-container>

  <!-- State Column -->
  <ng-container matColumnDef="state">
    <th mat-header-cell *matHeaderCellDef> State </th>
    <td mat-cell *matCellDef="let result"> {{ result.state }} </td>
  </ng-container>

  <!-- RGB Video Column -->
  <ng-container matColumnDef="rgbVideoPath">
    <th mat-header-cell *matHeaderCellDef> RGB Video </th>
    <td mat-cell *matCellDef="let result">
      <a *ngIf="result.rgbVideoPath" [href]="result.rgbVideoPath" target="_blank">View RGB Video</a>
    </td>
  </ng-container>

  <!-- Poseva Video Column -->
  <ng-container matColumnDef="posevaVideoPath">
    <th mat-header-cell *matHeaderCellDef> Poseva Video </th>
    <td mat-cell *matCellDef="let result">
      <a *ngIf="result.posevaVideoPath" [href]="result.posevaVideoPath" target="_blank">View Poseva Video</a>
    </td>
  </ng-container>

  <!-- MMSpeed Path Column -->
  <ng-container matColumnDef="mmSpeedPath">
    <th mat-header-cell *matHeaderCellDef> MMSpeed </th>
    <td mat-cell *matCellDef="let result">
      <a *ngIf="result.mmSpeedPath" [href]="result.mmSpeedPath" target="_blank">View MMSpeed Data</a>
    </td>
  </ng-container>

  <!-- MMTorque Path Column -->
  <ng-container matColumnDef="mmTorquePath">
    <th mat-header-cell *matHeaderCellDef> MMTorque </th>
    <td mat-cell *matCellDef="let result">
      <a *ngIf="result.mmTorquePath" [href]="result.mmTorquePath" target="_blank">View MMTorque Data</a>
    </td>
  </ng-container>

  <!-- IO Scanner Path Column -->
  <ng-container matColumnDef="ioScannerPath">
    <th mat-header-cell *matHeaderCellDef> IO Scanner </th>
    <td mat-cell *matCellDef="let result">
      <a *ngIf="result.ioScannerPath" [href]="result.ioScannerPath" target="_blank">View IO Scanner Data</a>
    </td>
  </ng-container>

  <!-- Header Row -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
