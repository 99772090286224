<div *ngIf="loading; else content" class="component-main" fxLayout="column" fxLayoutAlign="center center">
  <div fxLayoutAlign="center center" style="height: 60vh;">
    <!-- A loading spinner or text can appear here -->
    <mat-spinner diameter="40"></mat-spinner>
  </div>
</div>

<ng-template #content>
  <div>
    <div fxLayout="row" fxLayoutAlign="center start">
      <h2>{{ exerciseDetails.exercise?.name || 'Exercise Details' }}</h2>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" style="margin-bottom: 20px;">
      <button mat-stroked-button (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
        Back
      </button>
    </div>
  </div>

  <!-- <div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <h2>{{ exerciseDetails.exercise?.name || 'Exercise Details' }}</h2>
    </div>
  
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" style="margin-top: 10px;">
      <button mat-stroked-button (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
        Back
      </button>
  
      <button mat-stroked-button (click)="goForward()">
        Next
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
  </div>   -->
  
  <div *ngIf="!error && exerciseDetails; else errorBlock" style="width: 100%" class="component-main" fxLayout="column" fxLayoutGap="20px">

    <!-- Main Card Container -->
    <mat-card class="details-card">
      <mat-card-content class="textFont">
        
        <!-- Session Info Section -->
        <section class="session-details">
          <h3>Session Data</h3>
          <div fxLayout="column" fxLayoutAlign="start start" class="session-info-list">
            <div fxLayout="row" class="session-info-item">
              <b>State:</b>
              <span class="session-value">{{ exerciseDetails.session?.stateName || 'Not Available' }}</span>
            </div>

            <div fxLayout="row" class="session-info-item">
              <b>Start Date:</b>
              <span class="session-value">{{ exerciseDetails.session?.startDate || 'Not Available' }}</span>
            </div>

            <div fxLayout="row" class="session-info-item">
              <b>Stop Date:</b>
              <span class="session-value">{{ exerciseDetails.session?.stopDate || 'Not Available' }}</span>
            </div>

            <div fxLayout="row" class="session-info-item">
              <b>Max Duration:</b>
              <span class="session-value">{{ (exerciseDetails.exercise?.maxDurationMinute || 'Not Available') / 60000 }} minutes</span>
            </div>
          </div>

          <!-- Path Buttons -->
          <div class="path-buttons" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
            <button
              mat-raised-button
              color="primary"
              class="path-button"
              [disabled]="!exerciseDetails.session?.rgbVideoPath"
              (click)="exerciseDetails.session?.rgbVideoPath && openPath(exerciseDetails.session.rgbVideoPath)">
              RGB
            </button>

            <button
              mat-raised-button
              color="accent"
              class="path-button"
              [disabled]="!exerciseDetails.session?.posevaVideoPath"
              (click)="exerciseDetails.session?.posevaVideoPath && openPath(exerciseDetails.session.posevaVideoPath)">
              Poseva
            </button>

            <button
              mat-raised-button
              color="warn"
              class="path-button"
              [disabled]="!exerciseDetails.session?.mmSpeedPath"
              (click)="exerciseDetails.session?.mmSpeedPath && openPath(exerciseDetails.session.mmSpeedPath)">
              Speed
            </button>

            <button
              mat-raised-button
              color="primary"
              class="path-button"
              [disabled]="!exerciseDetails.session?.mmTorquePath"
              (click)="exerciseDetails.session?.mmTorquePath && openPath(exerciseDetails.session.mmTorquePath)">
              Torque
            </button>

            <button
              mat-raised-button
              color="accent"
              class="path-button"
              [disabled]="!exerciseDetails.session?.ioScannerPath"
              (click)="exerciseDetails.session?.ioScannerPath && openPath(exerciseDetails.session.ioScannerPath)">
              IO Scanner
            </button>
          </div>
        </section>

        <mat-divider style="margin: 20px 0;"></mat-divider>

        <!-- Graph Section -->
        <section class="skill-section">
          <h3>Speed & Torque Graph</h3>
          <div class="skill-container">
            <canvas id="speedTorqueChart"></canvas>
          </div>
        </section>

        <mat-divider style="margin: 20px 0;"></mat-divider>

        <!-- Video Section -->
        <!-- <section class="skill-section">
          <h3>Video Player</h3>
          <div class="skill-container">
            <button mat-raised-button (click)="playVideo()">Play Video</button>
          </div>
        </section> -->

        <!-- <mat-divider style="margin: 20px 0;"></mat-divider> -->

        <!-- Poseva Section -->
        <!-- <section class="skill-section">
          <h3>POSEVA</h3>
          <div class="skill-container">
          </div>
        </section> -->

        <!-- <mat-divider style="margin: 20px 0;"></mat-divider> -->

        <!-- IO Scanner Section -->
        <!-- <section class="skill-section">
          <h3>IO Scanner</h3>
          <div class="skill-container">
          </div>
        </section>

        <mat-divider style="margin: 20px 0;"></mat-divider> -->

        <!-- Teacher Grading Section -->
        <section class="teacher-grading">
          <h3>Grading</h3>
          <p class="grading-instructions">
            Assign grades or feedback for each data submission. The final grade is calculated based on... [FORMULA]
          </p>

          <div class="grading-rows">
            <div class="grading-item">
              <label>RGB Video:</label>
              <input type="number" min="0" max="100" [(ngModel)]="rgbGrade" />
            </div>
            <div class="grading-item">
              <label>Poseva:</label>
              <input type="number" min="0" max="100" [(ngModel)]="posevaGrade" />
            </div>
            <div class="grading-item">
              <label>Micromotor Speed:</label>
              <input type="number" min="0" max="100" [(ngModel)]="speedGrade" />
            </div>
            <div class="grading-item">
              <label>Micromotor Torque:</label>
              <input type="number" min="0" max="100" [(ngModel)]="torqueGrade" />
            </div>
            <div class="grading-item">
              <label>IO Scanner:</label>
              <input type="number" min="0" max="100" [(ngModel)]="ioGrade" />
            </div>
          </div>

          <div class="final-grade">
            <label><strong>Final Grade:</strong></label>
            <span class="grade-value">
              {{ computeFinalGrade() }} / 100
            </span>
          </div>
        </section>

        <div fxLayoutAlign="center center" class="submit-grade-container">
          <button mat-raised-button color="primary" (click)="submitGrade()" class="submit-grade-btn">
            Submit Grade
          </button>
        </div>

      </mat-card-content>
    </mat-card>
  </div>

  <ng-template #errorBlock>
    <div class="error" fxLayoutAlign="center center">
      {{ error }}
    </div>
  </ng-template>
</ng-template>

<ng-template #notAvailable>
  <span class="not-available">---</span>
</ng-template>
