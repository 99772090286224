import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { addMinutes, differenceInMinutes, isBefore } from 'date-fns';
import { DatePipe } from '@angular/common';
import { Helper } from 'src/app/helpers/helper';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConferenceService } from 'src/app/services/conference.service';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { TranslationService } from 'src/app/services/translation.service';
import { ConferencePost } from 'src/app/models/conferencePost';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { RoomService } from 'src/app/services/room.service';
import { VirtualRoomDTO } from 'src/app/models/dto/virtualRoomDTO';
import { CONFERENCE_DURATION_MINUTES } from 'src/app/models/conference-session/conferenceConstants';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { MasterService } from 'src/app/services/master.service';
import { TranslationDTO, TranslationEdit } from 'src/app/models/dto/translationDTO';
import { ConferencePresenterRole } from 'src/app/models/conference-session/conferencePresenterRole';
import { UserRole } from 'src/app/models/userRole';
import { ClassroomService } from 'src/app/services/classroom.service';

@Component({
  selector: 'app-app-conference-pop-up',
  templateUrl: './app-conference-pop-up.component.html',
  styleUrls: ['./app-conference-pop-up.component.scss']
})
export class AppConferencePopUpComponent implements OnInit {

  currentUser: User = undefined;
  sending: boolean = false;
  conferenceToEdit: ConferenceDTO = undefined;
  page: string = undefined;
  title: string = undefined;
  users: UserDTO[] = [];
  classrooms: ClassroomDTO[] = [];
  virtualRooms: VirtualRoomDTO[] = [];
  idModes: { value: number, name: string, toggle: boolean }[] = [
    { value: 1, name: "Exclusive speaker", toggle: false },
    { value: 2, name: "Shared speaker", toggle: true }
  ];

  forceMode: null | "private" | "public" = null;
  idMaster: number = undefined;
  substituteListSearchForm: FormControl<string> = new FormControl(undefined);
  presenterListSearchForm: FormControl<string> = new FormControl(undefined);
  classroomListSearchForm: FormControl<string> = new FormControl(undefined);
  virtualroomListSearchForm: FormControl<string> = new FormControl(undefined);

  static showDateOrTime: boolean = true;
  static startDateBackup: Date = undefined;
  dateForm: FormGroup = new FormGroup(
    {
      startDateForm: new FormControl(null, [Validators.required]),
      endDateForm: new FormControl(null, [Validators.required])
    },
    this.dateDifferenceValidator
  );

  nameForm: FormControl<string> = new FormControl('');
  descriptionForm: FormControl<string> = new FormControl('');
  idModeForm: FormControl<number> = new FormControl(this.idModes[0].value);
  isPublicForm: FormControl<boolean> = new FormControl(false, [Validators.required]);
  httpLinkForm: FormControl<string> = new FormControl({ value: '', disabled: true });
  substituteForm: FormControl<number> = new FormControl(undefined);
  presenterListForm: FormControl<number[]> = new FormControl([],[Validators.required]);
  classroomsListForm: FormControl<number[]> = new FormControl([], [Validators.required]);
  recordForm: FormControl<boolean> = new FormControl(false, [Validators.required]);
  privateForm: FormControl<boolean> = new FormControl(true);
  virtualRoomsListForm: FormControl<number[]> = new FormControl<number[]>([]);

  createVirtualRoom: boolean = false;
  nameRoomForm: FormControl<string> = new FormControl('', [Validators.required]);
  //descriptionRoomForm: FormControl<string> = new FormControl('');
  permanentRoomForm: FormControl<boolean> = new FormControl(false);

  nameTranslation: TranslationDTO = undefined;
  descriptionTranslation: TranslationDTO = undefined;

  stepperIndex: number = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private datePipe: DatePipe,
              private auth: AuthService,
              private dialogRef: MatDialogRef<AppConferencePopUpComponent>,
              private userService: UserService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private translation: TranslationService,
              private conferenceService: ConferenceService,
              private masterService: MasterService,
              private classroomService: ClassroomService,
              private roomService: RoomService,
              private dialog: MatDialog,
              public darkService: DarkThemeService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    this.page = this.data.page;
    this.forceMode = this.data.mode;

    AppConferencePopUpComponent.showDateOrTime = this.data?.dateOrTime ?? true;

    let startDate = addMinutes(new Date(), 5);
    let endDate = addMinutes(startDate, CONFERENCE_DURATION_MINUTES);

    if (this.data.idMaster)
      this.idMaster = this.data.idMaster;

    if (this.data.conference) {

      this.title = 'Edit conference';

      let conferenceToEdit: ConferenceDTO = this.data.conference;

      this.idModeForm.setValue(conferenceToEdit.idMode);
      this.isPublicForm.setValue(conferenceToEdit.isPublic === 1);

      this.substituteForm.setValue(conferenceToEdit.conferencePresenter.find(s => s.role === ConferencePresenterRole.Substitute)?.idPresenter);
      this.presenterListForm.setValue(conferenceToEdit.conferencePresenter.filter(p => p.role === ConferencePresenterRole.Presenter).map(p => p.idPresenter));
      this.classroomsListForm.setValue(conferenceToEdit.conferenceClassroom.map(c => c.idClassroom));
      this.recordForm.setValue(conferenceToEdit.lessonSession.recodingPlanned);
      this.privateForm.setValue(conferenceToEdit.lessonSession.privateRecording);
      this.virtualRoomsListForm.setValue(conferenceToEdit.lessonSession.lessonVirtualRoom?.map(r => r.idVirtualRoom) ?? []);

      if (conferenceToEdit.nameTranslation)
        this.nameForm.setValue(conferenceToEdit.nameTranslation[this.currentUser.defaultLanguage]);

      if (conferenceToEdit.descriptionTranslation)
        this.descriptionForm.setValue(conferenceToEdit.descriptionTranslation[this.currentUser.defaultLanguage]);

      this.nameTranslation = conferenceToEdit.nameTranslation;
      this.descriptionTranslation = conferenceToEdit.descriptionTranslation;

      startDate = new Date(conferenceToEdit.lessonSession.startPlanned);
      endDate = new Date(conferenceToEdit.lessonSession.endPlanned);

      if (!(this.data.isCopy ?? false))
        this.conferenceToEdit = conferenceToEdit;

      if (conferenceToEdit.lessonSession.typeId === 8)
        this.page = 'master';

    } else {

      this.presenterListForm.value.push(this.currentUser.id);

      if (this.isForcePrivate())
        this.isPublicForm.setValue(false);

      if (this.isForcePublic())
        this.isPublicForm.setValue(true);

      if (this.isConferencePage())
        this.title = this.isPublicForm.value
                   ? 'Create a new public conference'
                   : 'Create a new private conference';
      else if (this.isMasterPage())
        this.title = 'Create a new conference';
      else
        this.title = this.isPublicForm.value
                   ? 'Schedule a new public conference'
                   : 'Schedule a new private conference';

    }

    if (this.data.startData) {
      startDate = new Date(this.data.startData);
      endDate = addMinutes(startDate, (this.data.isCopy ?? false) ? differenceInMinutes(endDate, startDate) : CONFERENCE_DURATION_MINUTES);
    }

    if (startDate < new Date())
      startDate = addMinutes(new Date(), 5);

    let diff = differenceInMinutes(endDate, startDate);

    if (diff > CONFERENCE_DURATION_MINUTES || diff < 0)
      endDate = addMinutes(startDate, CONFERENCE_DURATION_MINUTES);

    AppConferencePopUpComponent.startDateBackup = startDate;

    this.dateForm.controls.startDateForm.setValue(this.datePipe.transform(startDate, this.getShowDateOrTime() ? 'yyyy-MM-ddTHH:mm' : 'HH:mm'));
    this.dateForm.controls.endDateForm.setValue(this.datePipe.transform(endDate, 'HH:mm'));

    this.getClassrooms();
    this.getUsers();
    this.getVirtualRooms();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async saveConference() {
    this.sending = true;

    let conference = new ConferencePost();

    conference.startPlanned = AppConferencePopUpComponent.getStartDate(this.dateForm).toUTCString();
    conference.endPlanned = AppConferencePopUpComponent.getEndDate(this.dateForm).toUTCString();
    conference.name = Helper.isNullOrEmpty(this.nameForm.value) ? 'Conference' : this.nameForm.value;
    conference.description = this.descriptionForm.value;
    conference.idMode = this.idModeForm.value;
    conference.isPublic = this.isPublicForm.value;
    conference.substitute = this.substituteForm.value;
    conference.presenters = conference.idMode === 1 ? [] : this.presenterListForm.value;
    conference.classrooms = conference.isPublic ? [] : this.classroomsListForm.value;
    conference.recodingPlanned = this.recordForm.value;
    conference.privateRecording = this.privateForm.value;
    conference.virtualRooms = this.virtualRoomsListForm.value;
    conference.language = this.translation.currentLang.lang;
    conference.nameTranslation = TranslationEdit.fromDTO(this.nameTranslation);
    conference.descriptionTranslation = TranslationEdit.fromDTO(this.descriptionTranslation);

    if (!this.conferenceToEdit) {
      let res = this.isMasterPage()
              ? this.masterService.postMasterConference(this.idMaster, conference)
              : this.conferenceService.createConference(conference);

      res.subscribe({
        next: async () => {
          console.log('New conference added');  
          this.snackBar.open(await firstValueFrom(this.translate.get('Conference added')), undefined, { duration: 3000 });
          
          this.sending = false;
          this.dialogRef.close(true);
        },
        error: err => {
          console.log(err);
          this.snackBar.open(err.error.Message, undefined, { duration: 3000 });

          this.sending = false;
          this.dialogRef.close();
        }
        });
    } else {
      let res = this.isMasterPage()
              ? this.masterService.putMasterConference(this.conferenceToEdit.idLesson, conference)
              : this.conferenceService.putConference(this.conferenceToEdit.id, conference)
      
      res.subscribe({
        next: async () => {
          console.log('Conference edited');        
          this.snackBar.open(await firstValueFrom(this.translate.get('Conference edited')), undefined, { duration: 3000 });
            
          this.sending = false;
          this.dialogRef.close(true);
        },
        error: err => {
          console.log(err);
          this.snackBar.open(err.error.Message, undefined, { duration: 3000 });
  
          this.sending = false;
          this.dialogRef.close();
        }
      });
    } 
  }

  addVirtualRoom() {
    this.sending = true;

    let newVirtualRoom = new VirtualRoomDTO();

    newVirtualRoom.name = this.nameRoomForm.value;
    //newVirtualRoom.description = this.descriptionRoomForm.value;
    newVirtualRoom.permanentRoom = this.permanentRoomForm.value ? 1 : 0;

    this.roomService.createVirtualRoom(newVirtualRoom)
      .subscribe({
        next: async res => {
          console.log('New virtual room added');
          this.snackBar.open(await firstValueFrom(this.translate.get('Virtual room added')), undefined, { duration: 3000 });

          this.nameRoomForm.reset();
          //this.descriptionRoomForm.reset();
          this.getVirtualRooms(Number((res as any).Message));
        
          this.createVirtualRoom = false;
          this.sending = false;
        },
        error: err => {
          console.log(err);
          this.snackBar.open(err.error.Message, undefined, { duration: 3000 });

          this.sending = false;
        }
      });
  }

  async deleteVirtualRoom(id: number) {
    const dialogRef = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('Delete virtual room')),
          body: await firstValueFrom(this.translate.get('Are you sure you want to delete this virtual room?'))
        }
      });

    dialogRef.afterClosed().subscribe(async res => {
      if (!res)
        return;

      this.sending = true;

      this.roomService.deleteVirtualRoom(id)
        .subscribe({
          next: async () => {
            console.log('Virtual room deleted');
            this.snackBar.open(await firstValueFrom(this.translate.get('Virtual room deleted')), undefined, { duration: 3000 });
  
            this.getVirtualRooms();
  
            this.sending = false;
          },
          error: err => {
            console.log(err);
            this.snackBar.open(err.error.Message, undefined, { duration: 3000 });
  
            this.sending = false;
          }
        });
    });
  }

  private updateSelectedRooms(id?: number) {
    if (id &&
        this.virtualRooms.findIndex(vr => vr.id === id) !== -1 &&
        !this.virtualRoomOptionDisabled(id))
      this.virtualRoomsListForm.value.push(id);

    this.virtualRoomsListForm.setValue(this.virtualRoomsListForm.value.filter(vr => this.virtualRooms.findIndex(r => r.id === vr) !== -1));
  }

  getUsers() {
    this.userService.getAllUsers([UserRole.Tutor, UserRole.Teacher, UserRole.Speaker])
      .subscribe(res => this.users = res as UserDTO[]);
  }

  getClassrooms() {
    this.classroomService.getClassrooms()
      .subscribe(res => this.classrooms = res as ClassroomDTO[]);
  }

  getVirtualRooms(newRoomId?: number) {
    this.roomService.getVirtualRooms()
      .subscribe(res => {
        this.virtualRooms = res;
        this.updateSelectedRooms(newRoomId);
      });
  }

  presenterOptionDisabled(userId: number): boolean {
    return (this.presenterListForm.value.length >= 3 && !this.presenterListForm.value.find((p: number) => p === userId));
  }

  virtualRoomOptionDisabled(virtualRoomId: number) {
    return this.virtualRoomsListForm.value.length >= 3
        && !this.virtualRoomsListForm.value.find((r: number) => r === virtualRoomId);
  }

  updateSelectableUsers(type: 'substitute' | 'presenter') {
    let index = this.presenterListForm.value.findIndex(p => p === this.substituteForm.value);

    if (index === -1)
      return;

    if (type === 'substitute')
      this.presenterListForm.value.splice(index, 1);

    if (type === 'presenter')
      this.substituteForm.setValue(undefined);
  }

  getSelectableUsers(type: 'substitute' | 'presenter') {
    if (type === 'substitute')
      return this.users.filter(u => this.presenterListForm.value.findIndex(p => p === u.id) === -1);

    if (type === 'presenter')
      return this.users.filter(u => this.substituteForm.value !== u.id);

    return this.users;
  }

  getSelectedUsers() {
    return this.users?.filter(u => this.presenterListForm.value.findIndex((ul: number) => ul === u.id) !== -1) ?? [];
  }

  getSelectedClassrooms() {
    return this.classrooms?.filter(c => this.classroomsListForm.value.findIndex((cl: number) => cl === c.id) !== -1) ?? [];
  }

  getSelectedVirtualRooms() {
    return this.virtualRooms?.filter(c => this.virtualRoomsListForm.value.findIndex((cl: number) => cl === c.id) !== -1) ?? [];
  }

  okBtnDisabled() {
    let check = !this.dateForm.valid ||
                !this.nameForm.valid ||
                !this.isPublicForm.valid ||
                this.sending;

    if (!this.isPublicForm.value && !this.isMasterPage())
      check = check ||
              !this.classroomsListForm.valid;

    if (this.idModeForm.value === 2)
      check = check ||
              !this.presenterListForm.valid;

    return check;
  }

  nextBtnDisabled() {

    if (this.stepperIndex === 0) {

      let check = !this.dateForm.valid;
    
      if (this.idModeForm.value === 2)
        check = check
             || !this.presenterListForm.valid;
      
      return check;

    }

    if (this.stepperIndex === 1) {
      return !this.isPublicForm.value && !this.isMasterPage()
           ? !this.classroomsListForm.valid
           : false;
    }

    return true;

  }

  isForcePrivate() {
    return this.forceMode === "private";
  }

  isForcePublic() {
    return this.forceMode === "public";
  }

  isConferencePage() {
    return this.page === "conference";
  }

  isMasterPage() {
    return this.page === "master";
  }

  maxDurationHours() {
    return CONFERENCE_DURATION_MINUTES / 60;
  }

  getShowDateOrTime() {
    return AppConferencePopUpComponent.showDateOrTime;
  }

  dateDifferenceValidator(group: FormGroup): ValidatorFn {
    if (!group)
      return null;

    let startDateForm = group.controls.startDateForm;
    let endDateForm = group.controls.endDateForm;

    let startDate = AppConferencePopUpComponent.getStartDate(group);
    let endDate = AppConferencePopUpComponent.getEndDate(group);

    if (isBefore(startDate, new Date()))
      startDateForm.setErrors({ futureDate: true });
    else
      startDateForm.setErrors(null);

    if (endDate <= startDate)
      endDateForm.setErrors({ dateDifference: true });
    else if (differenceInMinutes(endDate, startDate) > CONFERENCE_DURATION_MINUTES)
      endDateForm.setErrors({ durationLimit: true });
    else
      endDateForm.setErrors(null);

    return null;
  }

  private static getStartDate(group: FormGroup) {
    if (!group?.controls?.startDateForm?.value)
      return null;

    if (AppConferencePopUpComponent.showDateOrTime)
      return new Date(group.controls.startDateForm.value);

    let startDate = new Date(AppConferencePopUpComponent.startDateBackup);
    let startTime = Helper.convertTimeToHMS(group.controls.startDateForm.value);

    startDate.setHours(startTime[0]);
    startDate.setMinutes(startTime[1]);
    startDate.setSeconds(startTime[2]);

    return startDate;
  }

  private static getEndDate(group: FormGroup) {
    if (!group?.controls?.endDateForm?.value)
      return null;

    let endTime = Helper.convertTimeToHMS(group.controls.endDateForm.value);
    let endDate = this.getStartDate(group);

    endDate.setHours(endTime[0]);
    endDate.setMinutes(endTime[1]);
    endDate.setSeconds(endTime[2]);

    return endDate;
  }

}
