import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { addMinutes, differenceInMinutes, isBefore } from 'date-fns';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { ConferencePost } from 'src/app/models/conferencePost';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { ConferenceService } from 'src/app/services/conference.service';
import { firstValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AzureStorageService } from 'src/app/services/storage.service';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { ConferencePresenterRole } from 'src/app/models/conference-session/conferencePresenterRole';
import { TranslationService } from 'src/app/services/translation.service';
import { Router } from '@angular/router';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { Helper } from 'src/app/helpers/helper';
import { CONFERENCE_DURATION_MINUTES } from 'src/app/models/conference-session/conferenceConstants';
import { TranslationDTO, TranslationEdit } from 'src/app/models/dto/translationDTO';
import { UserService } from 'src/app/services/user.service';
import { UserRole } from 'src/app/models/userRole';
import { ClassroomService } from 'src/app/services/classroom.service';

@Component({
  selector: 'app-conference-webinar-popup',
  templateUrl: './conference-webinar-popup.component.html',
  styleUrls: ['./conference-webinar-popup.component.scss']
})
export class ConferenceWebinarPopupComponent implements OnInit {

  currentUser: User;
  sending: boolean = false;
  webinarToEdit: ConferenceDTO = undefined;

  classrooms: ClassroomDTO[] = [];
  users: UserDTO[] = [];
  tutors: UserDTO[] = [];
  speakers: UserDTO[] = [];

  moderatorSearchForm: FormControl = new FormControl(undefined);
  presenterListSearchForm: FormControl = new FormControl(undefined);
  classroomListSearchForm: FormControl = new FormControl(undefined);

  static showDateOrTime: boolean = true;
  static startDateBackup: Date = undefined;
  dateForm: FormGroup = new FormGroup(
    {
      startDateForm: new FormControl(null, [Validators.required]),
      endDateForm: new FormControl(null, [Validators.required])
    },
    this.dateDifferenceValidator
  );
  
  moderatorForm: FormControl<number> = new FormControl<number>(undefined, [Validators.required]);
  presenterListForm: FormControl<number[]> = new FormControl<number[]>([], [Validators.required]);
  nameForm: FormControl<string> = new FormControl<string>(undefined);
  descriptionForm: FormControl<string> = new FormControl<string>(undefined);
  headerForm: FormControl<string> = new FormControl<string>(undefined);
  footerForm: FormControl<string> = new FormControl<string>(undefined);
  summaryForm: FormControl<string> = new FormControl<string>(undefined);
  imageSrc: FormControl = new FormControl(undefined, [Validators.required]);
  isPrivateForm: FormControl<boolean> = new FormControl<boolean>(false, [Validators.required]);
  classroomsListForm: FormControl<number[]> = new FormControl<number[]>([], [Validators.required]);
  recordForm: FormControl<boolean> = new FormControl<boolean>(false, [Validators.required]);

  nameTranslation: TranslationDTO = undefined;
  descriptionTranslation: TranslationDTO = undefined;
  headerTranslation: TranslationDTO = undefined;
  footerTranslation: TranslationDTO = undefined;
  summaryTranslation: TranslationDTO = undefined;

  stepperIndex: number = 0;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private datePipe: DatePipe,
              private auth: AuthService,
              private dialogRef: MatDialogRef<ConferenceWebinarPopupComponent>,
              private snackBar: MatSnackBar,
              private azureService: AzureStorageService,
              private translate: TranslateService,
              private classroomService: ClassroomService,
              private router: Router,
              private translation: TranslationService,
              private conferenceService: ConferenceService,
              private userService: UserService,
              public darkService: DarkThemeService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    ConferenceWebinarPopupComponent.showDateOrTime = this.data?.dateOrTime ?? true;

    let startDate = addMinutes(new Date(), 5);
    let endDate = addMinutes(startDate, CONFERENCE_DURATION_MINUTES);

    if (this.data.webinar != undefined) {

      let webinarToEdit: ConferenceDTO = this.data.webinar;

      this.isPrivateForm.setValue(webinarToEdit.visibility === 0);
      this.recordForm.setValue(webinarToEdit.lessonSession.recodingPlanned);
      this.classroomsListForm.setValue(webinarToEdit.conferenceClassroom.map(c => c.idClassroom));
      this.presenterListForm.setValue(webinarToEdit.conferencePresenter.filter(p => p.role === ConferencePresenterRole.Presenter).map(a => a.idPresenter));
      this.moderatorForm.setValue(webinarToEdit.conferencePresenter.find(p => p.role === ConferencePresenterRole.Moderator)?.idPresenter);

      if (webinarToEdit.nameTranslation)
        this.nameForm.setValue(webinarToEdit.nameTranslation[this.currentUser.defaultLanguage]);

      if (webinarToEdit.descriptionTranslation)
        this.descriptionForm.setValue(webinarToEdit.descriptionTranslation[this.currentUser.defaultLanguage]);

      if (webinarToEdit.headerTranslation)
        this.headerForm.setValue(webinarToEdit.headerTranslation[this.currentUser.defaultLanguage]);

      if (webinarToEdit.footerTranslation)
        this.footerForm.setValue(webinarToEdit.footerTranslation[this.currentUser.defaultLanguage]);

      if (webinarToEdit.summaryTranslation)
        this.summaryForm.setValue(webinarToEdit.summaryTranslation[this.currentUser.defaultLanguage]);

      this.nameTranslation = webinarToEdit.nameTranslation;
      this.descriptionTranslation = webinarToEdit.descriptionTranslation;
      this.headerTranslation = webinarToEdit.headerTranslation;
      this.footerTranslation = webinarToEdit.footerTranslation;
      this.summaryTranslation = webinarToEdit.summaryTranslation;

      startDate = new Date(webinarToEdit.lessonSession.startPlanned);
      endDate = new Date(webinarToEdit.lessonSession.endPlanned);

      if (!(this.data.isCopy ?? false))
        this.webinarToEdit = webinarToEdit;
    }

    if (this.data.startData) {
      startDate = new Date(this.data.startData);
      endDate = addMinutes(startDate, (this.data.isCopy ?? false) ? differenceInMinutes(endDate, startDate) : CONFERENCE_DURATION_MINUTES);
    }

    if (startDate < new Date()) {
      startDate = addMinutes(new Date(), 5);
      endDate = addMinutes(startDate, CONFERENCE_DURATION_MINUTES);
    }

    let diff = differenceInMinutes(endDate, startDate);

    if (diff > CONFERENCE_DURATION_MINUTES || diff < 0)
      endDate = addMinutes(startDate, CONFERENCE_DURATION_MINUTES);

    ConferenceWebinarPopupComponent.startDateBackup = startDate;

    this.dateForm.controls.startDateForm.setValue(this.datePipe.transform(startDate, ConferenceWebinarPopupComponent.showDateOrTime ? 'yyyy-MM-ddTHH:mm' : 'HH:mm'));
    this.dateForm.controls.endDateForm.setValue(this.datePipe.transform(endDate, 'HH:mm'));

    this.fetchClassRoom();
    this.getUsers();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getUsers() {
    this.userService.getAllUsers([UserRole.Speaker, UserRole.Tutor])
      .subscribe(res => {

        res = res as UserDTO[];

        this.users = res.slice();
        this.moderatorSelected();

        // Vedi moderatorSelected()
        //this.speakers = res.filter(u => u.isSpeaker);
        //this.tutors = res.filter(u => u.isTutor);

      });
  }

  presenterOptionDisabled(userId: number): boolean {
    return this.presenterListForm.value.length >= 3
        && this.presenterListForm.value.findIndex((p: number) => p === userId) === -1;
  }

  moderatorSelected() {
    let availableTutors = this.users.slice();
    let availableSpeakers = this.users.slice();

    if (this.moderatorForm.value != undefined) {
      availableSpeakers = availableSpeakers.filter(s => s.id !== this.moderatorForm.value);
      this.presenterListForm.setValue(this.presenterListForm.value.filter(p => p !== this.moderatorForm.value));
    }

    if (this.presenterListForm.value.length > 0) {
      availableTutors = availableTutors.filter(t => !this.presenterListForm.value.some(p => p === t.id));

      if (this.presenterListForm.value.some(p => p === this.moderatorForm.value))
        this.moderatorForm.reset();
    }

    this.tutors = availableTutors;
    this.speakers = availableSpeakers;
  }

  okBtnDisabled() {
    let check = !this.dateForm.valid ||
                !this.moderatorForm.valid ||
                this.sending;

    if (this.isPrivateForm.value)
      check = check ||
              !this.classroomsListForm.valid;

    if (!this.webinarToEdit)
      check = check ||
              !this.imageSrc.valid;
      
    return check;
  }

  nextBtnDisabled() {

    if (this.stepperIndex === 0)
      return !this.dateForm.valid
          || !this.moderatorForm.valid
          || !this.presenterListForm.valid;

    if (this.stepperIndex === 1)
      return !this.webinarToEdit
           ? !this.imageSrc.valid
           : false;

    return true;

  }

  async saveWebinar() {
    this.sending = true;

    let webinar = new ConferencePost();

    webinar.startPlanned = ConferenceWebinarPopupComponent.getStartDate(this.dateForm).toUTCString();
    webinar.endPlanned = ConferenceWebinarPopupComponent.getEndDate(this.dateForm).toUTCString();
    webinar.name = Helper.isNullOrEmpty(this.nameForm.value) ? 'Webinar' : this.nameForm.value;
    webinar.description = this.descriptionForm.value;
    webinar.header = this.headerForm.value;
    webinar.footer = this.footerForm.value;
    webinar.summary = this.summaryForm.value;
    webinar.moderator = this.moderatorForm.value;
    webinar.presenters = this.presenterListForm.value.length === 0 ? [] : this.presenterListForm.value;
    webinar.idMode = 3;
    webinar.language = this.translation.currentLang.lang;
    webinar.visibility = this.isPrivateForm.value ? 0 : 1;
    webinar.classrooms = webinar.visibility === 0 ? this.classroomsListForm.value : [];
    webinar.recodingPlanned = this.recordForm.value;
    webinar.imageUrl = await this.azureService.uploadFile(this.imageSrc.value);
    webinar.nameTranslation = TranslationEdit.fromDTO(this.nameTranslation);
    webinar.descriptionTranslation = TranslationEdit.fromDTO(this.descriptionTranslation);
    webinar.headerTranslation = TranslationEdit.fromDTO(this.headerTranslation);
    webinar.footerTranslation = TranslationEdit.fromDTO(this.footerTranslation);
    webinar.summaryTranslation = TranslationEdit.fromDTO(this.summaryTranslation);

    if (!this.webinarToEdit)
      this.conferenceService.createConference(webinar).subscribe({
        next: async (success) => {
          console.log('New webinar added');
          this.snackBar.open(await firstValueFrom(this.translate.get('Webinar added')), undefined, { duration: 3000 });

          this.sending = false;
          this.dialogRef.close(true);

          this.router.navigate([`/webinar-showcase/${(success as any).Message}`], { queryParams: { page: 'showcase' } });
        },
        error: err => {
          console.log(err);
          this.snackBar.open(err.error.Message,undefined, { duration: 3000 });

          this.sending = false;
          this.dialogRef.close();
        }
      });
    else
      this.conferenceService.putConference(this.webinarToEdit.id, webinar).subscribe({
        next: async () => {
          console.log('Webinar edited');
          this.snackBar.open(await firstValueFrom(this.translate.get('Webinar edited')), undefined, { duration: 3000 });

          this.sending = false;
          this.dialogRef.close(true);
        },
        error: err => {
          console.log(err);
          this.snackBar.open(err.error.Message, undefined, { duration: 3000 });

          this.sending = false;
          this.dialogRef.close();
        }
      });
  }

  fetchClassRoom() {
    this.classroomService.getClassrooms()
      .subscribe(output => this.classrooms = output as ClassroomDTO[]);
  }

  getSelectedUsers() {
    return this.speakers?.filter(u => this.presenterListForm.value.findIndex((ul: number) => ul === u.id) !== -1) ?? [];
  }

  getSelectedClassrooms() {
    return this.classrooms?.filter(c => this.classroomsListForm.value.findIndex((cl: number) => cl === c.id) !== -1) ?? [];
  }

  maxDurationHours() {
    return CONFERENCE_DURATION_MINUTES / 60;
  }

  getShowDateOrTime() {
    return ConferenceWebinarPopupComponent.showDateOrTime;
  }

  dateDifferenceValidator(group: FormGroup): ValidatorFn {
    if (!group)
      return null;

    let startDateForm = group.controls.startDateForm;
    let endDateForm = group.controls.endDateForm;

    let startDate = ConferenceWebinarPopupComponent.getStartDate(group);
    let endDate = ConferenceWebinarPopupComponent.getEndDate(group);

    if (isBefore(startDate, new Date()))
      startDateForm.setErrors({ futureDate: true });
    else
      startDateForm.setErrors(null);

    if (endDate <= startDate)
      endDateForm.setErrors({ dateDifference: true });
    else if (differenceInMinutes(endDate, startDate) > CONFERENCE_DURATION_MINUTES)
      endDateForm.setErrors({ durationLimit: true });
    else
      endDateForm.setErrors(null);

    return null;
  }

  private static getStartDate(group: FormGroup) {
    if (!group?.controls?.startDateForm?.value)
      return null;

    if (ConferenceWebinarPopupComponent.showDateOrTime)
      return new Date(group.controls.startDateForm.value);

    let startDate = new Date(ConferenceWebinarPopupComponent.startDateBackup);
    let startTime = Helper.convertTimeToHMS(group.controls.startDateForm.value);

    startDate.setHours(startTime[0]);
    startDate.setMinutes(startTime[1]);
    startDate.setSeconds(startTime[2]);

    return startDate;
  }

  private static getEndDate(group: FormGroup) {
    if (!group?.controls?.endDateForm?.value)
      return null;

    let endTime = Helper.convertTimeToHMS(group.controls.endDateForm.value);
    let endDate = this.getStartDate(group);

    endDate.setHours(endTime[0]);
    endDate.setMinutes(endTime[1]);
    endDate.setSeconds(endTime[2]);

    return endDate;
  }

}
