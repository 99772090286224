import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppLessonPopUpComponent } from 'src/app/pages/lessons/app-lesson-pop-up/app-lesson-pop-up.component';
import { CustomerAddComponent } from 'src/app/popup/customer-add/customer-add.component';
import { CourseContentDTO } from 'src/app/models/dto/courseContentDTO';
import { EventDialogData } from 'src/app/models/eventDialogData';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { LessonService } from 'src/app/services/lessons.service';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { SideNavService } from 'src/app/services/sidenav.service';
import { SurveyTemplateDialogComponent } from 'src/app/survey/survey-template-dialog/survey-template-dialog.component';
import { CourseAddComponent } from 'src/app/pages/course/course-add/course-add.component';
import { MasterAddComponent } from 'src/app/pages/master/master-add/master-add.component';
import { PackageAddComponent } from 'src/app/pages/package/package-add/package-add.component';
import { ImportUsersLessonsComponent } from 'src/app/popup/import-users-lessons/import-users-lessons.component';
import { AddSubscriptionLevelComponent } from 'src/app/popup/add-subscription-level/add-subscription-level.component';
import { Helper } from 'src/app/helpers/helper';
import { AppConferencePopUpComponent } from 'src/app/pages/conferences/app-conference-pop-up/app-conference-pop-up.component';
import { ConferenceWebinarPopupComponent } from 'src/app/pages/webinars/conference-webinar-popup/conference-webinar-popup.component';
import { EnvironmentHelper, EnvironmentParameterType } from 'src/app/models/environmentVariables';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Output()
  itemSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  title: string = EnvironmentHelper.getConfigAuto(this.auth, EnvironmentParameterType.Title);
  currentUser: User;

  constructor(private auth: AuthService,
              private router: Router,
              private dialog: MatDialog,
              public sideNav: SideNavService,
              private lesson: LessonService,
              private snackBar: MatSnackBar,
              public darkService: DarkThemeService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    this.sideNav.navbarOpened();
  }

  addLesson(): void {
    this.itemSelected.emit(true);

    let eventData: EventDialogData = {
      classId: undefined,
      classrooms: this.sideNav.classrooms,
      rooms: this.sideNav.rooms,
      type: 1
    };
      
    const dialogRef = this.dialog.open(AppLessonPopUpComponent, {
      width: '500px',
      data: { eventData, page: "lesson" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.lesson.createLesson(result.title, result.description, result.classId, result.roomId, result.recordPlan, result.private, result.smilyRec)
          .subscribe({
            next: () => {
              console.log('New lesson added');
              this.snackBar.open('Lesson added!', undefined, { duration: 3000 });
            },
            error: err => {
              console.error(err);
              this.snackBar.open(Helper.getLessonErrorMessage(err.status), undefined, { duration: 5000 });
            }
          });
      }
    });
  }

  addPublicConference(){
    this.itemSelected.emit(true);

    const dialogRef = this.dialog.open(AppConferencePopUpComponent, {
      width: '600px',
      data: { mode: "public", page: "conference" },
      autoFocus: false 
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return;

      this.ngOnInit();
    });
  }

  addPrivateConference(): void {
    this.itemSelected.emit(true);
      
    const dialogRef = this.dialog.open(AppConferencePopUpComponent, {
      width: '600px',
      data: { mode: "private", page: "conference" },
      autoFocus: false 
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return;

      this.ngOnInit();
    });
  }

  addSurvey() {
    this.itemSelected.emit(true);

    const dialogRef = this.dialog.open(SurveyTemplateDialogComponent, {
      width: '500px'
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if (!result)
        return;

      this.router.navigate(['/survey-creator/', result.Message]);
    });
  }

  addCourse() {
    this.itemSelected.emit(true);
    this.dialog.open(CourseAddComponent, { width: '600px' });
  }

  addMaster() {
    this.itemSelected.emit(true);
    this.dialog.open(MasterAddComponent, { width: '600px' });
  }

  addTemplateMaster() {
    this.itemSelected.emit(true);
    this.dialog.open(MasterAddComponent, { width: '600px', data: { isTemplate: true } });
  }

  addPackage() {
    this.itemSelected.emit(true);
    this.dialog.open(PackageAddComponent, { width: '600px' });
  }

  addWebinar() {
    this.itemSelected.emit(true);
      
    const dialogRef = this.dialog.open(ConferenceWebinarPopupComponent, {
      width: '600px',
      data: { webinar: null },
      autoFocus: false 
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return;
    });
  }

  addCustomer() {
    this.itemSelected.emit(true);
    this.dialog.open(CustomerAddComponent, { width: '500px' });
  }

  importFromFile() {
    this.itemSelected.emit(true);
    this.dialog.open(ImportUsersLessonsComponent, { width: '500px' });
  }

  addSubscriptionLevel() {
    this.itemSelected.emit(true);
    this.dialog.open(AddSubscriptionLevelComponent, { width: '500px' });
  }

  ThemeSelection() {
    this.itemSelected.emit(true);
    this.darkService.storeThemeSelection(); 
  }

  goToFavourite(content: CourseContentDTO) {
    this.itemSelected.emit(true);

    if (content.type === 3)
      this.snackBar.open('Access this content from the course', 'Dismiss', { duration: 3000 });
  }

  isSL() {
    return this.auth.isSL();
  }
}
