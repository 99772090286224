<div class="component-main" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">

    <h2>{{ 'Exercises' | translate }}</h2>

    <!-- Action and Search Section -->
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="action-area" fxFlexFill>
        <!-- Search Box -->
        <div fxLayout="row" fxFlex="20" class="search-container">
            <mat-form-field fxFlex>
                <mat-label>{{ 'Search' | translate }}</mat-label>
                <input matInput [(ngModel)]="searchValue" placeholder="{{ 'Type to search...' | translate }}" (input)="getFilteredExercises(searchValue)">
                <button matSuffix mat-icon-button matTooltip="{{ 'Clear search' | translate }}" (click)="resetSearch()">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <!-- Refresh and Add Exercise Buttons -->
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="60" fxLayoutGap="15px" style="margin-bottom: 14.6px">
            <button mat-fab color="primary" matTooltip="{{ 'Refresh' | translate }}" (click)="refreshExercises()">
                <mat-icon>refresh</mat-icon>
            </button>
            <button mat-fab extended color="accent" matTooltip="{{ 'Add New Exercise' | translate }}" 
                    (click)="openAddExerciseDialog()" *ngIf="auth.isTeacher()">
                <mat-icon>add</mat-icon>
                {{ 'Add Exercise' | translate }}
            </button>
        </div>

        <!-- Mode Selection -->
        <div fxLayout="row" fxLayoutAlign="end center" fxFlex="20">
            <mat-form-field color="primary">
                <mat-label>{{ 'Mode' | translate }}</mat-label>
                <mat-select [(value)]="mode" (selectionChange)="getExercises()">
                    <mat-option value="incoming">{{ 'Incoming' | translate }}</mat-option>
                    <mat-option value="performed">{{ 'Performed' | translate }}</mat-option>
                    <mat-option value="expired">{{ 'Expired' | translate }}</mat-option>
                    <mat-option value="all">{{ 'All' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <!-- Conditional No Exercises Message -->
    <div *ngIf="exercises.length === 0 && auth.isTeacher()" fxLayoutAlign="center center">
        <app-error-message customClass="warning"
            [errorTitle]="'No exercises available' | translate"
            [errorContent]="'Add an exercise using the button above' | translate">
        </app-error-message>
    </div>
    
    <div *ngIf="exercises.length === 0 && auth.isStudent()" fxLayoutAlign="center center">
        <app-error-message customClass="warning"
            [errorTitle]="'No exercises available' | translate"
            [errorContent]="'Check back later for active exercises' | translate">
        </app-error-message>
    </div>

    <!-- Exercise List -->
    <div style="width: 100%">
        <mat-accordion>
            <mat-expansion-panel *ngFor="let exercise of exercises | slice: pageStart: pageEnd"
                                 style="margin-bottom: 10px; border-radius: 4px;"
                                 (opened)="toggleResults(exercise)">
                
                <mat-expansion-panel-header>
                    <div fxLayout="column" fxFlex>
                        <mat-panel-title fxLayout="row">
                            <span class="exercise-title" fxFlex style="vertical-align: middle; margin-top: 15px;">
                                <strong>{{ exercise.name }}</strong>
                            </span>
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="textFont" fxFlex="20">
                                {{ 'Teacher' | translate }}: {{ exercise.teacher?.name || 'N/A' }} {{ exercise.teacher?.surname || '' }}
                            </div>
            
                            <div class="textFont" fxFlex>
                                {{ exercise.startPlanned | date: 'dd/MM/yyyy HH:mm' }}
                                {{ 'to' | translate }}
                                {{ exercise.endPlanned | date: 'HH:mm' }}
                            </div>
            
                            <div class="textFont" fxFlex>
                                <ng-container *ngIf="exercise.classrooms?.length > 0; else noClassroom">
                                    {{ exercise.classrooms.join(', ') }}
                                </ng-container>
                                <ng-template #noClassroom>
                                    {{ 'No classroom assigned' | translate }}
                                </ng-template>
                            </div>                
    
                            <div fxLayoutAlign="end center" fxFlex="30">
                                
                                <button *ngIf="auth.isTeacher()" mat-icon-button color="primary"
                                    (click)="openEditExerciseDialog(exercise); $event.stopPropagation();"
                                    [disabled]="!isEditable(exercise)"
                                    [matTooltip]="'Edit Exercise' | translate">
                                    <mat-icon>edit</mat-icon>
                                </button>

                                <button *ngIf="auth.isTeacher()" mat-icon-button color="warn"
                                    (click)="deleteExercise(exercise); $event.stopPropagation();"
                                    [matTooltip]="'Delete Exercise' | translate">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </mat-panel-description>
                    </div>
                </mat-expansion-panel-header>
    
                <mat-divider style="margin-bottom: 20px"></mat-divider>
                
                <!-- Description Section -->
                <div>
                    <strong>{{ 'Description' | translate }}:</strong>
                    <p [ngClass]="{'truncate': !exercise.showFullDescription}">
                        {{ exercise.description || ('No additional details available' | translate) }}
                    </p>
                    <button *ngIf="exercise.description?.length > 100" (click)="exercise.showFullDescription = !exercise.showFullDescription">
                        {{ exercise.showFullDescription ? ('Show Less' | translate) : ('Show More' | translate) }}
                    </button>
                </div>

                <!-- Loading Spinner -->
                <div *ngIf="exercise.loading" style="text-align: center; margin-top: 10px;">
                    <mat-spinner diameter="30"></mat-spinner>
                </div>
                
                <!-- Results Section -->
                <div *ngIf="exercise.results && exercise.results.length && (auth.isTeacher() || auth.isAdmin()); else noResults">
                    <strong>{{ 'Exercise Results' | translate }}:</strong>
                    <table mat-table [dataSource]="exercise.results" class="mat-elevation-z8">

                        <ng-container matColumnDef="userName">
                            <th mat-header-cell *matHeaderCellDef> User </th>
                            <td mat-cell *matCellDef="let result"> {{ result.userName }} </td>
                        </ng-container>
                
                        <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef> Start Date </th>
                        <td mat-cell *matCellDef="let result"> {{ result.startDate ? (result.stopDate | date: 'medium') : '---' }} </td>
                        </ng-container>
                
                        <ng-container matColumnDef="stopDate">
                        <th mat-header-cell *matHeaderCellDef> Stop Date </th>
                        <td mat-cell *matCellDef="let result"> {{ result.stopDate ? (result.stopDate | date: 'medium') : '---' }} </td>
                        </ng-container>
                
                        <ng-container matColumnDef="rgbVideoPath">
                            <th mat-header-cell *matHeaderCellDef> RGB Video </th>
                            <td mat-cell *matCellDef="let result">
                                <button mat-icon-button color="primary" [disabled]="!result.rgbVideoPath"
                                    (click)="result.rgbVideoPath && openPath(result.rgbVideoPath)"
                                    [matTooltip]="result.rgbVideoPath ? 'View RGB Video' : 'Not Available'">
                                    <mat-icon>videocam</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="posevaVideoPath">
                            <th mat-header-cell *matHeaderCellDef> Poseva Video </th>
                            <td mat-cell *matCellDef="let result">
                                <button mat-icon-button color="accent" [disabled]="!result.posevaVideoPath"
                                    (click)="result.posevaVideoPath && openPath(result.posevaVideoPath)"
                                    [matTooltip]="result.posevaVideoPath ? 'View Poseva Video' : 'Not Available'">
                                    <mat-icon>play_circle_filled</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="mmSpeedPath">
                            <th mat-header-cell *matHeaderCellDef> MMSpeed </th>
                            <td mat-cell *matCellDef="let result">
                                <button mat-icon-button color="warn" [disabled]="!result.mmSpeedPath"
                                    (click)="result.mmSpeedPath && openPath(result.mmSpeedPath)"
                                    [matTooltip]="result.mmSpeedPath ? 'View MMSpeed Data' : 'Not Available'">
                                    <mat-icon>speed</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="mmTorquePath">
                            <th mat-header-cell *matHeaderCellDef> MMTorque </th>
                            <td mat-cell *matCellDef="let result">
                                <button mat-icon-button color="primary" [disabled]="!result.mmTorquePath"
                                    (click)="result.mmTorquePath && openPath(result.mmTorquePath)"
                                    [matTooltip]="result.mmTorquePath ? 'View MMTorque Data' : 'Not Available'">
                                    <mat-icon>settings</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="ioScannerPath">
                            <th mat-header-cell *matHeaderCellDef> IO Scanner </th>
                            <td mat-cell *matCellDef="let result">
                                <button mat-icon-button color="accent" [disabled]="!result.ioScannerPath"
                                    (click)="result.ioScannerPath && openPath(result.ioScannerPath)"
                                    [matTooltip]="result.ioScannerPath ? 'View IO Scanner Data' : 'Not Available'">
                                    <mat-icon>scanner</mat-icon>
                                </button>
                            </td>
                        </ng-container>                        

                        <ng-container matColumnDef="stateName">
                            <th mat-header-cell *matHeaderCellDef> State </th>
                            <td mat-cell *matCellDef="let result"> {{ result.stateName }} </td>
                          </ng-container>
                        
                        <ng-container matColumnDef="results">
                            <th mat-header-cell *matHeaderCellDef> Results </th>
                            <td mat-cell *matCellDef="let exercise">
                                <button mat-icon-button color="primary"
                                    (click)="goToExercise(exercise.exerciseId)"
                                    [matTooltip]="'Go To Exercise' | translate">
                                    <mat-icon>assignment</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                
                        <!-- Header Row -->
                        <tr mat-header-row *matHeaderRowDef="['userName', 'startDate', 'stopDate', 'rgbVideoPath', 'posevaVideoPath', 'mmSpeedPath', 'mmTorquePath', 'ioScannerPath', 'stateName', 'results']"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['userName', 'startDate', 'stopDate', 'rgbVideoPath', 'posevaVideoPath', 'mmSpeedPath', 'mmTorquePath', 'ioScannerPath', 'stateName', 'results'];"></tr>
                    </table>
                </div>
                
                <ng-template #noResults>
                    <ng-container *ngIf="auth.isTeacher() || auth.isAdmin(); else studentMessage">
                      <p>{{ 'No results available for this exercise' | translate }}</p>
                    </ng-container>
                    <ng-template #studentMessage>
                      <ng-container *ngIf="exercise.results && exercise.results.length > 0; else noSubmission">
                        <p>{{ 'Exercise submitted' | translate }}</p>
                      </ng-container>
                      <ng-template #noSubmission>
                        <p>{{ 'Exercise not submitted' | translate }}</p>
                      </ng-template>
                    </ng-template>
                </ng-template>                  

            </mat-expansion-panel>
        </mat-accordion>
    </div>    
    
    <!-- Pagination -->
    <div style="width: 100%; display: flex; justify-content: center;">
        <mat-paginator 
            *ngIf="exercises.length > 0"
            [showFirstLastButtons]="true"
            [length]="totalExercises"
            [pageIndex]="pageIndex"
            [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 20]"
            [color]="darkService.isSetDark ? 'accent' : 'primary'"
            (page)="onPageChange($event)"
            class="mat-elevation-z3">
        </mat-paginator>
    </div>
</div>
