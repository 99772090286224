import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClassroomService } from 'src/app/services/classroom.service';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { ExerciseDTO } from 'src/app/models/dto/exerciseDTO';
import { DatePipe } from '@angular/common';
import { addHours, addMinutes, isBefore } from 'date-fns';
import { AzureStorageService } from 'src/app/services/storage.service';
import { ExerciseService } from 'src/app/services/exercise.service';
import { AuthService } from 'src/app/services/auth.service';
import { firstValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-exercise-pop-up',
  templateUrl: './exercise-pop-up.component.html',
  styleUrls: ['./exercise-pop-up.component.scss']
})
export class ExercisePopUpComponent implements OnInit {
  exerciseForm: FormGroup;
  classrooms: ClassroomDTO[] = [];
  mode: 'add' | 'edit';
  currentUser: User = null;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private classroomService: ClassroomService,
    private azureService: AzureStorageService,
    private exerciseService: ExerciseService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ExercisePopUpComponent>,
    public authService: AuthService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: { mode: 'add' | 'edit'; exercise?: ExerciseDTO }
  ) {
    this.mode = data?.mode || 'add';
  }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    
    this.initForm();
    this.getClassrooms();

    if (this.mode === 'edit' && this.data.exercise) {
        const now = new Date();
        let startDate = this.data.exercise.startPlanned ? new Date(this.data.exercise.startPlanned) : addMinutes(new Date(), 5);
        let endDate = this.data.exercise.endPlanned ? new Date(this.data.exercise.endPlanned) : addHours(startDate, 2);

        if (startDate <= now) {
          console.error('Cannot edit an exercise that has already started.');
          this.dialogRef.close();
          return;
      }

        if (endDate <= startDate) {
            endDate = addHours(startDate, 2);
        }

        this.data.exercise.startPlanned = startDate;
        this.data.exercise.endPlanned = endDate;

        this.prefillForm(this.data.exercise);
    }

  }

  private initForm(): void {
    const defaultDate = new Date();
    const defaultStartDate = new Date(defaultDate.getTime() + 2 * 60 * 60 * 1000); // now + 2 hours
    const defaultEndDate = new Date(defaultStartDate.getTime() + 60 * 60 * 1000); // +1 hour after start

    this.exerciseForm = this.fb.group({
      details: this.fb.group({
        title: ['', Validators.required],
        startDate: [this.mode === 'add' ? defaultStartDate.toISOString().slice(0, 16) : '', [Validators.required, this.dateValidator]],
        endDate: [this.mode === 'add' ? defaultEndDate.toISOString().slice(0, 16) : '', [Validators.required, this.dateValidator]],
        description: ['', Validators.required]
      }),
      classroomAssignment: this.fb.group({
        classroom: [[], [Validators.required, this.validateClassroomSelection]]
      }),
      skills: this.fb.group({
        videoRecording: [false],
        poseRecording: [false],
        micromotorData: [false],
        scannerIntegration: [false]
      }),
      maxDuration: this.fb.group({
        duration: [60, [Validators.required, Validators.min(1)]]
      }),
    });
  }

  private validateClassroomSelection(control): { [key: string]: boolean } | null {
    return control.value && control.value.length > 0 ? null : { noClassroomSelected: true };
  }

  private getClassrooms(): void {
    this.classroomService.getClassrooms().subscribe({
      next: (res: ClassroomDTO[]) => {
        this.classrooms = res;
      },
      error: (err) => {
        console.error('Error fetching classrooms:', err);
      }
    });
  }

  private prefillForm(exercise: ExerciseDTO): void {
    if (!exercise) {
      console.warn('No exercise data to prefill the form.');
      return;
    }

    console.log('Prefilled exercise form:', this.exerciseForm.value);

    this.exerciseForm.patchValue({
      details: {
        title: exercise.name || '',
        startDate: exercise.startPlanned ? this.datePipe.transform(exercise.startPlanned, 'yyyy-MM-ddTHH:mm') : '',
        endDate: exercise.endPlanned ? this.datePipe.transform(exercise.endPlanned, 'yyyy-MM-ddTHH:mm') : '',
        description: exercise.description || ''
      },
      classroomAssignment: {
        classroom: exercise.classroomIds || []
      },
      skills: {
        videoRecording: exercise.videoRecording || false,
        poseRecording: exercise.poseRecording || false,
        micromotorData: exercise.micromotorData || false,
        scannerIntegration: exercise.scannerIntegration || false
      },
      maxDuration: {
        duration: (exercise.maxDurationMinute || 60000) / 60000
      }
    });
  }

  private dateValidator(control: UntypedFormControl): { [s: string]: boolean } {
    if (!control.value) return null;

    if (isBefore(new Date(control.value), new Date())) return { invalidDate: true };

    return null;
  }

  private getStartDate(): Date {
    const date = new Date(this.exerciseForm.value.details.startDate);
    return new Date(this.exerciseForm.value.details.startDate);
  }

  private getEndDate(): Date {
    const date = new Date(this.exerciseForm.value.details.endDate);
    return new Date(this.exerciseForm.value.details.endDate);
  }

  private okBtnDisabled(): boolean {
    const startDate = this.getStartDate();
    const endDate = this.getEndDate();

    if (!this.exerciseForm.valid) {
        console.error("Form is invalid in okBtnDisabled.");
        return true;
    }

    if (endDate <= startDate) {
        console.error("Invalid date range: End date must be after start date.", { startDate, endDate });
        return true;
    }

    return false;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  async onSave(): Promise<void> {
    if (this.exerciseForm.valid && !this.okBtnDisabled()) {
      const formValues = this.exerciseForm.value;
  
      const exercise: ExerciseDTO = {
        id: this.data.exercise?.id,
        name: formValues.details.title,
        description: formValues.details.description,
        startPlanned: this.getStartDate(),
        endPlanned: this.getEndDate(),
        classroomIds: formValues.classroomAssignment.classroom,
        videoRecording: !!formValues.skills.videoRecording,
        poseRecording: !!formValues.skills.poseRecording,
        micromotorData: !!formValues.skills.micromotorData,
        scannerIntegration: !!formValues.skills.scannerIntegration,
        maxDurationMinute: formValues.maxDuration.duration * 60000,
        blobFilePath: ``
      };
  
      try {
        console.log('Exercise sent for update or creation:', exercise);
  
        let response: ExerciseDTO;
        if (this.mode === 'add') {
          response = await firstValueFrom(this.exerciseService.addExercise(exercise));
        } else {
          response = await firstValueFrom(this.exerciseService.editExercise(exercise));
        }
  
        this.snackBar.open('Exercise saved successfully', 'Dismiss', { duration: 3000 });
        this.dialogRef.close(response);
      } catch (error) {
        console.error('Error saving exercise:', error);
        this.snackBar.open('Error saving exercise', 'Dismiss', { duration: 3000 });
      }
    } else {
      console.error("Validation failed for saving exercise.");
      Object.keys(this.exerciseForm.controls).forEach((key) => {
        console.error(`${key} control:`, this.exerciseForm.get(key)?.errors);
      });
    }
  }  

  async uploadResults(): Promise<void> {

    const resultValues = this.exerciseForm.get('results').value;

    const results = {
        startDate: resultValues.startDate,
        stopDate: resultValues.stopDate,
        rgbVideoPath: resultValues.rgbVideoPath,
        posevaVideoPath: resultValues.posevaVideoPath,
        mmSpeedPath: resultValues.mmSpeedPath,
        mmTorquePath: resultValues.mmTorquePath,
        ioScannerPath: resultValues.ioScannerPath,
        mmSpeedLimitPercentage: resultValues.mmSpeedLimitPercentage,
        mmTorqueLimitPercentage: resultValues.mmTorqueLimitPercentage,
        state: resultValues.state,
        created: new Date()
    };

    try {
        const jsonBlob = new Blob([JSON.stringify(results)], { type: 'application/json' });
        //const blobFilePath = `customers/${this.authService.getCurrentUser()}_test_asisme/exercises/${this.data.exercise?.id}/Results_${Date.now()}.json`; // this line is not to be used like this
        const blobFilePath = `exercises/${this.data.exercise?.id}/Results_${Date.now()}.json`
        const file = new File([jsonBlob], blobFilePath, { type: 'application/json' });
        await this.azureService.uploadFile(file);
        

        if (!blobFilePath) {
            console.error('Failed to upload results to Azure Storage.');
            this.snackBar.open('Error uploading results to Azure Storage', 'Dismiss', { duration: 3000 });
            return;
        }

        this.snackBar.open('Results uploaded successfully!', 'Dismiss', { duration: 3000 });
    } catch (error) {
        console.error('Error uploading results:', error);
        this.snackBar.open('Error uploading results', 'Dismiss', { duration: 3000 });
    }
  }
}
