<mat-drawer-container hasBackdrop="false" id="conference-container">

    <mat-drawer #globalChat
        mode="over"
        position="end"
        style="width: 400px"
        (openedChange)="globalChatOpened = !globalChatOpened">

        <app-directional-chat
            [session]="session"
            [addUsersOnConnection]="false"
            [aggregateChats]="false"
            [forwardActive]="false"
            [replyActive]="false"
            [hideActive]="false"
            [globalUsersChat]="true"
            [globalUsersChatEnabledRoles]="['publisher', 'presenter', 'participant']"
            [read]="globalChatOpened"
            (unread)="newChatMessages = $event"
            (onClose)="globalChat.close()"
            title="Messages">
        </app-directional-chat>

    </mat-drawer>

    <div *ngIf="initialSelection; else streamLayout" fxLayout="column" fxLayoutAlign="center center" style="height: 100%">
        <app-source-selection
            [OV]="OV"
            [videoDevices]="getDevices('video', 'all')"
            [audioDevices]="getDevices('audio', 'all')"
            [audioEnabled]="isPublisher()"
            [username]="getUsername()"
            [entityName]="'conference'"
            [lockForm]="lockJoin"
            [acceptedNetwork]="validStreamingQualities"
            (result)="$event == null ? leave() : joinConference($event)"> <!--[audioDeviceEnabled]="isPresenter()"-->
        </app-source-selection>
    </div>

</mat-drawer-container>

<ng-template #streamLayout>

    <button mat-fab class="bottom-button"
        [style.bottom]="toolbarOpened ? '72px' : '0'"
        [color]="toolbarOpened ? 'accent' : 'primary'"
        (click)="toolbarOpened = !toolbarOpened"
        [matBadge]="getAllNotifications()"
        [matBadgeHidden]="toolbarOpened || getAllNotifications() === 0"
        matBadgeColor="accent">
        <mat-icon>{{ toolbarOpened ? 'expand_more' : 'expand_less' }}</mat-icon>
    </button>

    <button mat-mini-fab class="top-right-button"
        *ngIf="virtualRoomId && isPublisher()"
        (click)="leave(true, ['/conference', this.id])"
        [matTooltip]="'Leave room' | translate"
        color="warn">
        <mat-icon>output</mat-icon>
    </button>

    <div *ngIf="conferenceRemainingMinutes() <= 10" class="messageBanner" style="top: 40px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: 'The conference will be closed in x minutes' | translate: { value: conferenceRemainingMinutes() },
                                            icon: 'info'
                                         }">
        </ng-container>
    </div>

    <div *ngIf="!publisher && presenters.length === 0 && participants.length > 0" class="messageBanner" style="top: 120px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: publisherName ? (('Conference paused by' | translate) + ' ' + publisherName) : ('Conference paused' | translate),
                                            icon: 'info'
                                         }">
        </ng-container>
    </div>

    <div *ngIf="lostConnection() || streamingQualityValues.length > 0" class="messageBanner" style="bottom: 120px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: 'No connection. Go to an area with a better connection' | translate,
                                            icon: 'warning'
                                         }">
        </ng-container>
    </div>

    <div fxLayout="column" fxLayoutGap="20px" class="layout-container">

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex>
    
            <div *ngIf="publisher" fxLayoutAlign="center center" fxFlex>
                <div *ngIf="!publisherShare; else shareLayout" class="presenter-stream">
                    <ng-container *ngTemplateOutlet="publisherStream; context: { displayOutline: publisher.userId === currentUser.id, sharingMode: false }"></ng-container>
                </div>
            </div>
            <div *ngIf="presenters.length > 0" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px" fxFlex="25">
                <div *ngFor="let presenter of presenters; last as isLast" class="presenter-stream" [style.height]="getPresenterHeight()">
                    <app-user-video
                        [streamManager]="presenter.manager"
                        [name]="presenter.name"
                        [color]="presenter.color"
                        [btnOptionsMarginBottom]="toolbarOpened && isLast ? '50px' : '0'"
                        [displayOutline]="presenter.userId === currentUser.id"
                        [handRaised]="presenter.handRaised"
                        [lowerHandEnabled]="isPublisher() || isPresenter()"
                        (onLowerHand)="toggleHand(presenter.userId, false)"
                        visualType="cover">

                        <ng-container *ngTemplateOutlet="
                            participantsOptionsBtn;
                            context: {
                                participant: presenter,
                                compactMode: false,
                                disableOptions: false,
                                disableSetAs: false,
                                disableKickUser: false
                            }" optionBtn>
                        </ng-container>

                    </app-user-video>
                </div>
            </div>

        </div>

    </div>

    <app-session-toolbar
        [hidden]="!toolbarOpened"
        [localParticipant]="localParticipant?.manager"
        [videoDevices]="getDevices('video', 'share')"
        [isVideoOverride]="isVideoOverride()"
        [hasScreenShareCapabilities]="hasScreenShareCapabilities"
        [shareActive]="isPublisher() || isPresenter() || isPublishing()"
        [shareEnabled]="isPublishing()"
        [shareDeviceId]="localParticipantShareVideoDeviceId"
        [isSharing]="isSharing()"
        [(fullScreen)]="isFullScreen"
        [handRaiseActive]="!isPublisher() && !isPresenter() && !isPublishing()"
        [(handRaise)]="isHandRaised"
        [recordingActive]="isPublisher() && conference?.lessonSession?.recodingPlanned"
        [isRecording]="isRecording"
        [recordingEnabled]="!togglingRecording"
        [httpLink]="httpLink"
        (handRaiseChange)="toggleHand(currentUser.id, $event)"
        (onRecordingChange)="toggleRecording($event)"
        (onStartShare)="startShare($event)"
        (onStopShare)="stopShare()"
        (onVisualTypeChange)="visualType = $event"
        (onLeave)="leave(true)"
        class="bottom-container mat-elevation-z24"> <!--[toggleAudioActive]="isPublisher() || isPresenter() || isPublishing()"-->

        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex leftBtns>

            <span style="font-weight: bold">{{ publishingInfo ? publishingInfo : '' }}</span>

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                <div *ngIf="isRecording" class="mat-elevation-z4" style="display: flex; align-items: center; justify-content: center; padding-left: 5px; padding-right: 10px;">
                    <button mat-icon-button>
                        <mat-icon color="warn">
                            fiber_manual_record
                        </mat-icon>
                    </button>
                    <span>{{ 'Recording' | translate }}</span>
                </div>
    
                <div>
                    <button mat-mini-fab
                        (click)="participantsListOpened = !participantsListOpened"
                        [disabled]="participants.length === 0"
                        [color]="participantsListOpened ? 'accent' : 'primary'"
                        [matTooltip]="'Participants' | translate"
                        [matBadge]="newHandNotifications"
                        [matBadgeHidden]="newHandNotifications === 0"
                        matBadgeColor="accent">
                        <mat-icon>
                            people
                        </mat-icon>
                    </button>
                </div>
            </div>
            
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex rightBtns>

            <div fxLayput="row" fxLayoutGap="10px">
                <button mat-mini-fab
                    (click)="globalChat.toggle()"
                    [color]="globalChatOpened ? 'accent' : 'primary'"
                    [matTooltip]="'Chat' | translate"
                    [matBadge]="newChatMessages"
                    [matBadgeHidden]="newChatMessages === 0"
                    matBadgeColor="accent">
                    <mat-icon>
                        chat
                    </mat-icon>
                </button>

                <!-- <button mat-mini-fab
                    *ngIf="isPublisher()"
                    [matMenuTriggerFor]="roomsMenu"
                    [matMenuTriggerData]="{ rooms: virtualRooms }"
                    [matTooltip]="'Room' | translate"
                    color="accent">
                    <mat-icon>
                        chat
                    </mat-icon>
                </button> -->

                <button mat-mini-fab 
                    *ngIf="isPublisher() || isPresenter()"
                    (click)="toggleRoomsToolbar()"
                    [matTooltip]="'Virtual rooms' | translate"
                    [color]="toolbarRoomsOpened ? 'accent' : 'primary'">
                    <mat-icon>groups</mat-icon>
                </button>

                <button mat-mini-fab
                    *ngIf="virtualRoomId"
                    (click)="leave(true, ['/conference', this.id])"
                    [matTooltip]="'Leave room' | translate"
                    color="warn">
                    <mat-icon>output</mat-icon>
                </button>
            </div>

            <span style="font-weight: bold" *ngIf="!virtualRoomId">
                {{ 'Conference' | translate }} "{{ conference?.name }}" {{ 'has started x minutes ago' | translate: { value: conferenceElapsedMinutes() } }}
            </span>

            <span style="font-weight: bold" *ngIf="virtualRoomId">
                {{ 'Room' | translate }} "{{ room?.name }}"
            </span>
        
        </div>

    </app-session-toolbar>

    <app-rooms-toolbar
        [hidden]="!toolbarRoomsOpened"
        [rooms]="virtualRooms"
        [conferenceId]="id"
        [currentRoomId]="virtualRoomId"
        [currentUserId]="currentUser.id"
        [selectEnabled]="isPublisher()"
        (onSelect)="selectedRoomData = $event"
        (onJoin)="handleJoinEvent($event, currentUser.id)"
        (onClose)="toolbarRoomsOpened = false"
        class="right-container">
    </app-rooms-toolbar>

    <ng-container *ngIf="selectedRoomData">
        <app-room-card
            [roomData]="selectedRoomData"
            [conferenceId]="id"
            [selectedUsers]="selectedUsers"
            (onData)="handleDataEvent($event)"
            (onConnectedUsers)="handleConnectedUsers($event)"
            (onClose)="selectedRoomData = undefined"
            class="room-card-container">
        </app-room-card>
    </ng-container>

    <app-session-participants
        [hidden]="!participantsListOpened"
        [session]="session"
        [(participants)]="participants"
        [optionMenuBtn]="participantsOptionsBtn"
        [read]="participantsListOpened"
        [lowerAllHandsActive]="isPublisher() || isPresenter()"
        [lowerHandEnabled]="isPublisher() || isPresenter()"
        [selectionEnabled]="selectedRoomData"
        [selectedParticipantsDisabled]="connectedUsers"
        (onLowerHand)="toggleHand($event, false)"
        (unread)="newHandNotifications = $event"
        (onCurrentUserHandRaised)="isHandRaised = $event"
        (onLowerAllHands)="toggleHand(null, false)"
        (onPriorityPosition)="priorityPosition = $event"
        (selectedParticipantsChange)="selectedUsers = $event"
        (onClose)="participantsListOpened = false"
        mode="standard"
        dragBoundary="#conference-container">
    </app-session-participants>
   
</ng-template>

<ng-template #shareLayout>
    <div class="presenter-stream">
        <app-user-video *ngIf="currentUser.id !== publisherShare.userId || publisherShare.type !== 'screen'; else currentUserScreenSharing"
            [streamManager]="publisherShare.manager"
            [name]="publisherShare.name"
            [color]="publisherShare.color"
            [displayOutline]="publisherShare.userId === currentUser.id"
            [btnOptionsMarginBottom]="toolbarOpened ? '50px' : '0'"
            [visualType]="visualType"
            [displayName]="false"
            [displayAudio]="false">

            <ng-container *ngTemplateOutlet="
                participantsOptionsBtn;
                context: {
                    participant: publisher,
                    compactMode: false,
                    disableOptions: true,
                    disableSetAs: false,
                    disableKickUser: false
                }" optionBtn>
            </ng-container>

        </app-user-video>
        <div class="share-stream">
            <ng-container *ngTemplateOutlet="publisherStream; context: { displayOutline: false, sharingMode: true }"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #publisherStream let-displayOutline="displayOutline" let-sharingMode="sharingMode">
    <app-user-video 
        [streamManager]="publisher.manager"
        [name]="publisher.name"
        [color]="publisher.color"
        [compactMode]="sharingMode"
        [dragEnabled]="sharingMode"
        [btnOptionsMarginBottom]="toolbarOpened && sharingMode == false ? '50px' : '0'"
        [cursor]="sharingMode ? 'move' : '' "
        [z_index]="sharingMode ? 10 : 1 "
        [displayOutline]="displayOutline"
        [visualType]="visualType"
        [handRaised]="publisher.handRaised"
        [lowerHandEnabled]="isPublisher() || isPresenter()"
        (onLowerHand)="toggleHand(publisher.userId, false)"
        dragBoundary="#conference-container">

        <ng-container *ngTemplateOutlet="
            participantsOptionsBtn;
            context: {
                participant: publisher,
                compactMode: sharingMode,
                disableOptions: false,
                disableSetAs: sharingMode,
                disableKickUser: sharingMode
            }" optionBtn>
        </ng-container>

    </app-user-video>
</ng-template>

<ng-template #currentUserScreenSharing>
    <div class="share-stream-screen" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <mat-icon style="color:white">screen_share</mat-icon>
        <span style="color:white">{{ 'You are sharing your screen' | translate }}</span>
    </div>
</ng-template>

<mat-menu #streamOptionMenu="matMenu" xPosition="after" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-userId="userId" let-name="name" let-mode="mode" let-handRaised="handRaised">
        <button mat-menu-item
            *ngIf="userId === currentUser.id && mode === 'subject' && localParticipantVideoDeviceId !== false"
            [matMenuTriggerFor]="devicesMenu"
            [matMenuTriggerData]="{ devices: getDevices('video', 'subject'), type: 'video' }">
            <mat-icon>videocam</mat-icon>
            {{ 'Video Device' | translate }}
        </button>
        <button mat-menu-item
            *ngIf="userId === currentUser.id && mode === 'subject'"
            [matMenuTriggerFor]="devicesMenu"
            [matMenuTriggerData]="{ devices: getDevices('audio', 'subject'), type: 'audio' }">
            <mat-icon>mic</mat-icon>
            {{ 'Audio Device' | translate }}
        </button>
        <button mat-menu-item
            *ngIf="userId === currentUser.id && mode === 'subject' && localParticipantVideoDeviceId !== false"
            (click)="localParticipantMirror = !localParticipantMirror; changeSource(localParticipantVideoDeviceId, 'video');"
            [class.mat-menu-highlight]="localParticipantMirror">
            <mat-icon>switch_video</mat-icon>
            {{ 'Mirror' | translate }}
        </button>
    </ng-template>
</mat-menu>

<mat-menu #devicesMenu="matMenu" xPosition="after" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-devices="devices" let-type="type">
        <button mat-menu-item
            *ngFor="let device of devices"
            (click)="changeSource(device.deviceId, type)"
            [class.mat-menu-highlight]="localParticipantVideoDeviceId === device.deviceId || localParticipantAudioDeviceId === device.deviceId">
            {{ device.label }}
        </button>
        <!--button mat-menu-item
            *ngIf="type === 'video'"
            (click)="changeSource(false, 'video')"
            [class.mat-menu-highlight]="localParticipantVideoDeviceId === false">
            {{ 'No camera' | translate }}
        </button-->
    </ng-template>
</mat-menu>

<ng-template #participantsOptionsBtn
    let-participant="participant"
    let-compactMode="compactMode"
    let-disableOptions="disableOptions"
    let-disableSetAs="disableSetAs"
    let-disableKickUser="disableKickUser">

    <div fxLayout="row" [fxLayoutGap]="compactMode ? '0px' : '5px'">

        <ng-template #compactOptionsBtn [ngIf]="currentUser.id === participant?.userId && !disableOptions">
            <button mat-icon-button
                *ngIf="compactMode; else standardOptionsBtn"
                (click)="isFullScreen = false"
                [matMenuTriggerFor]="streamOptionMenu"
                [matMenuTriggerData]="participant">
                <mat-icon>more_vert</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardOptionsBtn>
            <button mat-mini-fab
                (click)="isFullScreen = false"
                [matMenuTriggerFor]="streamOptionMenu"
                [matMenuTriggerData]="participant"
                color="primary">
                <mat-icon>more_vert</mat-icon>
            </button>
        </ng-template>

        <ng-template #compactKickUserBtn [ngIf]="isPublisher() &&
                                                 currentUser.id !== participant?.userId &&
                                                 !disableKickUser">
            <button mat-icon-button
                *ngIf="compactMode; else standardKickUserBtn"
                (click)="isFullScreen = false; kickParticipant(participant?.manager?.stream?.connection?.connectionId)"
                [matTooltip]="'Kick user' | translate"
                color="warn">
                <mat-icon>person_remove</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardKickUserBtn>
            <button mat-mini-fab
                (click)="isFullScreen = false; kickParticipant(participant?.manager?.stream?.connection?.connectionId)"
                [matTooltip]="'Kick user' | translate"
                color="warn">
                <mat-icon>person_remove</mat-icon>
            </button>
        </ng-template>

        <ng-template #compactSetAsPublisherBtn [ngIf]="(
                                                        (isPresenter(participant) && isSharedPresenters()) ||
                                                        (!isPresenter(participant) && isExclusivePresenter())
                                                       ) &&
                                                       isPublisher() &&
                                                       !isPublisher(participant) &&
                                                       !disableSetAs">
            <button mat-icon-button
                *ngIf="compactMode; else standardSetAsPublisherBtn"
                (click)="isPublishing(participant?.userId) ? setUserAsPresenter(participant?.userId, false) : askUserToBePresenter(participant?.userId, participant?.name)"
                [disabled]="switchPublisherLock"
                [matTooltip]="(isPublishing(participant?.userId) ? 'Remove from publisher' : 'Set as publisher') | translate"
                color="accent">
                <mat-icon>{{ isPublishing(participant?.userId) ? 'voice_over_off' : 'record_voice_over' }}</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardSetAsPublisherBtn>
            <button mat-mini-fab
                (click)="isPublishing(participant?.userId) ? setUserAsPresenter(participant?.userId, false) : askUserToBePresenter(participant?.userId, participant?.name)"
                [disabled]="switchPublisherLock"
                [matTooltip]="(isPublishing(participant?.userId) ? 'Remove from publisher' : 'Set as publisher') | translate"
                color="primary">
                <mat-icon>{{ isPublishing(participant?.userId) ? 'voice_over_off' : 'record_voice_over' }}</mat-icon>
            </button>
        </ng-template>

    </div>

</ng-template>

<ng-template #messageBanner let-message="message" let-icon="icon">
    <mat-card>
        <mat-card-header fxLayoutAlign="center center">
            <mat-card-title>
                <mat-icon *ngIf="icon" style="margin-right: 10px; vertical-align: middle;">{{ icon }}</mat-icon>
                {{ message }}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content></mat-card-content>
    </mat-card>
</ng-template>
