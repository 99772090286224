<div class="component-main">
  <div fxLayoutAlign="center center" fxLayout="column">
    <h2>{{ title }}</h2>
    <h4 *ngIf="authorName != null" style="margin-top: 0px">
      {{ 'By' | translate }}
      <span [style.color]="isDark() ? '#00acc1' : 'firebrick'">
        {{ ' ' + authorName }}
      </span>
      {{ ' ' + ('on' | translate) + ' ' + (created | date: 'dd/MM/yyyy') }}
    </h4>
    <span *ngIf="isStandardMode()" style="margin-bottom: 20px;">{{'There are' | translate}} {{numberOfTeachers}} {{'teachers and' | translate}} {{numberOfStudents}}
      {{'students in this class' | role | translate}}</span>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" style="margin-bottom: 10px">
    <button mat-stroked-button (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
      {{ 'Back' | translate }}
    </button>
    <button mat-fab color="primary"
      *ngIf="isStandardMode()"
      (click)="transferClassroom()"
      [matTooltip]="'Click this button if you want to transfer the current class' | translate">
      <mat-icon>swap_horiz</mat-icon>
    </button>
    <button mat-fab extended color="primary"
      *ngIf="isStandardMode()"
      (click)="hasCalendar ? removeCalendar() : addCalendar()"
      [disabled]="!currentUser.googleEnabled || idAuthor !== currentUser.id || sending"
      [matTooltip]="(hasCalendar ? 'Remove' : 'Add') | translate">
      <mat-icon>{{ hasCalendar ? 'close' : 'add' }}</mat-icon>
      {{ 'Google calendar' | translate }}
    </button>
  </div>

  <div fxLayoutAlign="center center">
    <mat-form-field color="primary" fxFlex>
      <mat-label>{{ 'Search' | translate }}</mat-label>
      <input matInput (keyup)="searchWord($event)" #searchInput>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="center top" fxLayoutGap="20px">

    <div class="mat-elevation-z8" fxFlex="50">
      <table mat-table [dataSource]="users" #usersSort="matSort" matSort>

        <ng-container matColumnDef="surname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Surname' | translate }} </th>
          <td mat-cell *matCellDef="let element">{{ element.surname }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }} </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'username' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.username }}</td>
        </ng-container>
  
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Role' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ getRole(element) | translate }}</td>
        </ng-container>
  
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ 'Action' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button
              (click)="removeUserFromClass(element)"
              [matTooltip]="'Remove user from classroom' | translate">
              <mat-icon style="color: red">
                remove_circle_outline
              </mat-icon>
            </button>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">{{ ('No data matching the word' | translate) + " " + searchInput.value}}</td>
        </tr>

      </table>
      <mat-paginator [pageSizeOptions]="paginatorPages" [pageSize]="paginatorDefault" #usersPaginator></mat-paginator>
    </div>

    <div class="mat-elevation-z8" fxFlex="50">
      <table mat-table [dataSource]="allUsers" #allUsersSort="matSort" matSort>

        <ng-container matColumnDef="surname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Surname' | translate }} </th>
          <td mat-cell *matCellDef="let element">{{ element.surname }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }} </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'username' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.username }}</td>
        </ng-container>
  
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Role' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ getRole(element) | translate }}</td>
        </ng-container>
  
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ 'Action' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button
              (click)="addUserToCurrentClass(element)"
              [matTooltip]="'Add user to classroom' | translate">
              <mat-icon style="color: green">
                add_circle_outline
              </mat-icon>
            </button>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">{{ ('No data matching the word' | translate) + ' "' + searchInput.value + '"' }}</td>
        </tr>

      </table>
      <mat-paginator [pageSizeOptions]="paginatorPages" [pageSize]="paginatorDefault" #allUsersPaginator></mat-paginator>
    </div>

  </div>
</div>
