import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExercisePopUpComponent } from './exercise-pop-up/exercise-pop-up.component';
import { ExerciseDTO } from 'src/app/models/dto/exerciseDTO';
import { ExerciseService } from 'src/app/services/exercise.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { firstValueFrom } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { ClassroomService } from 'src/app/services/classroom.service';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { AzureStorageService } from 'src/app/services/storage.service';
import { ExerciseSessionDTO } from 'src/app/models/dto/exerciseSessionDTO';
import { ExerciseResultsPopupComponent } from './exercise-results-popup/exercise-results-popup.component';

@Component({
  selector: 'app-exercises',
  templateUrl: './exercises.component.html',
  styleUrls: ['./exercises.component.scss']
})
export class ExercisesComponent implements OnInit, OnDestroy {
  exercises: ExerciseDTO[] = [];
  classrooms: ClassroomDTO[] = [];
  searchValue: string = '';
  mode: 'all' | 'incoming' | 'expired' | 'performed' = 'all';
  pageIndex: number = 0;
  pageSize: number = 20;
  pageStart: number = 0;
  pageEnd: number = this.pageSize;
  totalExercises: number = 0;
  backupExercises: ExerciseDTO[] = [];
  fetchClassRoom: any;

  constructor(
    public auth: AuthService,
    private dialog: MatDialog,
    private exerciseService: ExerciseService,
    private classroomService: ClassroomService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private azureService: AzureStorageService,
    public darkService: DarkThemeService
  ) {}

  ngOnInit(): void {
    this.getExercises();
    this.fetchClassRooms();
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
  }

  updatePagination(): void {
    this.pageStart = this.pageIndex * this.pageSize;
    this.pageEnd = this.pageStart + this.pageSize;
  }  

  getExercises(): void {
    this.exerciseService.getExercises().subscribe({
        next: (data) => {
            console.log('Fetched exercises:', data);
            this.backupExercises = data.sort(
                (a, b) => new Date(b.startPlanned).getTime() - new Date(a.startPlanned).getTime()
            );
            this.totalExercises = this.backupExercises.length;
            this.updatePaginatedExercises();
            this.updatePagination();

            if (this.classrooms?.length > 0) {
                this.mapClassroomsToExercises();
            }
        },
        error: (err) => {
            console.error('Error fetching exercises:', err);
            this.snackBar.open('Failed to fetch exercises. Please try again.', 'Close', { duration: 3000 });
        }
    });
  }

  toggleResults(exercise: ExerciseDTO): void {
    if (!exercise.results) {
      exercise.loading = true;
      this.exerciseService.getExerciseResults(exercise.id).subscribe({
        next: (results) => {
          results.sort((a, b) => new Date(b.stopDate).getTime() - new Date(a.stopDate).getTime());
          exercise.results = results;
          exercise.loading = false;
          console.log('Fetched results for exercise:', results);
        },
        error: (err) => {
          exercise.loading = false;
          console.error('Error fetching results for exercise:', err);
        }
      });
    }
  }

  fetchClassRooms() {
    this.classroomService.getClassrooms()
        .subscribe(output => {
            this.classrooms = output as ClassroomDTO[];
            if (this.classrooms?.length > 0) {
              this.mapClassroomsToExercises();
          } else {
              console.log('No classrooms fetched to map');
          }
        });
  }

  mapClassroomsToExercises() {
    this.exercises.forEach(exercise => {

        if (exercise.classroomIds) {
            exercise.classrooms = exercise.classroomIds
                .map(classroomId => {
                    const classroom = this.classrooms.find(c => c.id === classroomId);
                    return classroom?.name;
                })
                .filter(name => name) as string[];

        } else {
            exercise.classrooms = [];
            console.log('No classrooms found for Exercise ID:', exercise.id);
        }
    });
  }

  updatePaginatedExercises(): void {
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = Math.min(startIndex + this.pageSize, this.backupExercises.length);
    this.exercises = this.backupExercises.slice(startIndex, endIndex);
  }

  onPageChange(event: any): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updatePagination();
  }
  
  changePage(event?: PageEvent): void {
    let minIndex = 0; 
    let maxIndex = this.pageSize;
    
    if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        minIndex = event.pageIndex * event.pageSize;
        maxIndex = (event.pageIndex + 1) * event.pageSize;
    }
    
    this.exercises = this.getFilteredExercises(this.searchValue).filter((exercise, i) => i >= minIndex && i < maxIndex);
}


  openAddExerciseDialog(): void {
    const dialogRef = this.dialog.open(ExercisePopUpComponent, {
      width: '600px',
      data: { mode: 'add', page: 'exercise' },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe((newExercise: ExerciseDTO) => {
      if (newExercise) {
        this.refreshExercises();
      }
    });
  }

  openEditExerciseDialog(exercise: ExerciseDTO): void {
    const dialogRef = this.dialog.open(ExercisePopUpComponent, {
      width: '600px',
      data: { mode: 'edit', exercise: { ...exercise } },
      autoFocus: false,
      restoreFocus: false
    });

    console.log('Exercise passed to edit dialog:', exercise);

    dialogRef.afterClosed().subscribe((updatedExercise: ExerciseDTO) => {
      if (updatedExercise) {
        this.exerciseService.editExercise(updatedExercise).subscribe({
          next: (response) => {
            const index = this.backupExercises.findIndex((e) => e.id === updatedExercise.id);
            if (index !== -1) {
              this.backupExercises[index] = response;
              this.updatePaginatedExercises();
            }
            this.refreshExercises();
            this.snackBar.open('Exercise updated successfully.', 'Close', { duration: 3000 });
          },
          error: (err) => {
            console.error('Error updating exercise:', err);
            this.snackBar.open('Failed to update exercise. Please try again.', 'Close', { duration: 3000 });
          }
        });
      }
    });
  }

  isEditable(exercise: ExerciseDTO): boolean {
    return new Date(exercise.startPlanned) > new Date();
  }

  async deleteExercise(exercise: ExerciseDTO): Promise<void> {
    const dialogRef = this.dialog.open(GenericPopupComponent, {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Delete exercise')),
        body: await firstValueFrom(
          this.translate.get('Are you sure you want to delete the exercise?', { name: exercise.name })
        )
      }
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!res) return;

      this.exerciseService.deleteExercise(exercise.id).subscribe({
        next: () => {
          this.backupExercises = this.backupExercises.filter((e) => e.id !== exercise.id);
          this.totalExercises = this.backupExercises.length;
          this.updatePaginatedExercises();
          this.snackBar.open('Exercise deleted successfully.', 'Close', { duration: 3000 });
        },
        error: (err) => {
          console.error('Error deleting exercise:', err);
          this.snackBar.open('Failed to delete exercise. Please try again.', 'Close', { duration: 3000 });
        }
      });
    });
  }

  resetSearch(): void {
    if (this.resetSearch) this.searchValue = '';

    this.pageIndex = 0;
    this.pageSize = 10;

    this.changePage();
    this.updatePaginatedExercises();
  }

  getFilteredExercises(searchValue: string): ExerciseDTO[] {
    if (!searchValue) return this.exercises;

    return this.exercises.filter(exercise => 
        exercise.name?.toLowerCase().includes(searchValue.toLowerCase()) || 
        exercise.description?.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  refreshExercises(): void {
    this.getExercises();
  }

  openViewResultsDialog(exerciseId: number): void {
    this.exerciseService.getExerciseResults(exerciseId).subscribe(
      (results) => {
        this.dialog.open(ExerciseResultsPopupComponent, {
          data: results,
        });
      },
      (error) => {
        console.error('Error fetching exercise results:', error);
        alert('Failed to fetch exercise results. Check console for details.');
      }
    );
  }

  openPath(path: string): void {
    if (path) {
        window.open(path, '_blank');
    } else {
        console.error('Path is invalid or empty.');
    }
  }

  goToExercise(exerciseId: number): void {
    if (!exerciseId) {
      console.error('Exercise ID is missing. Please check if the exercise object has a valid ID.');
      return;
    }
  
    const currentUserId = this.auth.getCurrentUser().id;
    const url = this.router.navigate(['/exercise-details'], {
      queryParams: { exerciseId, userId: currentUserId }
    }).toString();
    }  

  toggleDescription(exercise: ExerciseResultsPopupComponent): void {
    exercise.showFullDescription = !exercise.showFullDescription;
  }

  refreshPage(): void {
    this.router.navigate([this.router.url]);
  }  

}
