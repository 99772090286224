import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helpers/helper';
import { CourseContentDTO } from 'src/app/models/dto/courseContentDTO';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { CalendarService } from 'src/app/services/calendar.service';

@Component({
  selector: 'app-content-visualizer',
  templateUrl: './content-visualizer.component.html',
  styleUrls: ['./content-visualizer.component.scss']
})
export class ContentVisualizerComponent implements OnInit {

  lesson: LessonSessionDTO;
  contents: CourseContentDTO[] = [];

  constructor(public dialogRef: MatDialogRef<ContentVisualizerComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private calendar: CalendarService,
              private router: Router) { }

  ngOnInit(): void {
    this.lesson = this.data.lesson;

    this.calendar.getLessonContents(this.lesson.id)
                .subscribe(output => this.contents = output);
  }

  goToContent(content: CourseContentDTO) {
    this.router.navigate(['/course-content', content.id], 
    { 
      queryParams: {
        start: (content.courseContentTracker && content.courseContentTracker.length > 0) ? content.courseContentTracker[0].stopPosition : 0
      }
    });

    this.onNoClick();
  }
  
  onNoClick() {
    this.dialogRef.close(false);
  }

  getHelper() {
    return Helper;
  }

}
