import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SurveyService } from 'src/app/services/survey.service';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { SurveyAnswerDTO } from 'src/app/models/dto/surveyAnswerDTO';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-survey-page',
  templateUrl: './survey-page.component.html',
  styleUrls: ['./survey-page.component.scss']
})
export class SurveyPageComponent implements OnInit {

  currentUser: User = null;
  result: any;
  scoreA: number;
  json: any;
  idSurvey: number;
  idLesson: number;
  surveyDto: SurveyDTO;
  isPreview: boolean;

  idContent: number;

  constructor(private route: ActivatedRoute,
              private surveyService: SurveyService,
              private snackbar: MatSnackBar,
              private location: Location,
              private auth: AuthService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    this.surveyDto = new SurveyDTO();

    if (this.route.snapshot.params['id']) {

      this.idSurvey = this.route.snapshot.params['id'];
      this.idLesson = this.route.snapshot.queryParams['idLesson'];
      this.idContent = this.route.snapshot.queryParams['idContent'];
      this.isPreview = this.route.snapshot.queryParams['view'] == 'preview';

      this.surveyService.getSurvey(this.idSurvey)
        .subscribe(output => {
          this.surveyDto = output;

          if (this.isPreview)
            this.json = this.surveyDto.surveyJSON;
      });

    }
  }

  startSurvey() {
    this.json = this.surveyDto.surveyJSON;
  }

  sendData(risultato: any) {

    console.log(risultato.data);

    this.result = risultato.data;
    this.scoreA = risultato.scoreA;

    if (!this.isPreview)
      this.saveSurvey();

  }

  saveSurvey() {
    let surveyAnswer= new SurveyAnswerDTO();

    surveyAnswer.idLesson = this.idLesson;
    surveyAnswer.surveyJSON = JSON.stringify(this.result);
    surveyAnswer.scoreA = this.scoreA;
    surveyAnswer.scoreNote = '';
    surveyAnswer.state = 1;

    this.surveyService.postSurveyAnswer(this.idLesson,surveyAnswer)
    .subscribe({
      next: data => {
        console.log(data);
        this.snackbar.open('Survey saved', '', { duration: 3000 });
      },
      error: err => {
        console.log(err);
        this.snackbar.open(err.error.Message, '', { duration: 3000 });
      }
    });
  }

  goBack() {
    this.location.back();
  }

}
