import { BrowserModule } from '@angular/platform-browser';
import { Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/logins/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ClassroomComponent } from './pages/classroom/classroom.component';
import { LessonsComponent } from './pages/lessons/lessons.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { ProfileUserComponent } from './pages/profile-user/profile-user.component';
import { JoinSessionDialogComponent } from './pages/lessons/join-session-dialog/join-session-dialog.component';
import { VideoSessionComponent } from './pages/video-session/video-session.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserListComponent } from './pages/user-list/user-list.component';
import { ClassroomIdComponent } from './pages/classroom-id/classroom-id.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { CalendarUserListComponent } from './pages/calendar-user-list/calendar-user-list.component';
import { CalendarHeaderComponent } from './pages/calendar/calendar-header/calendar-header.component';
import { AppLessonPopUpComponent } from './pages/lessons/app-lesson-pop-up/app-lesson-pop-up.component';
import { MaterialModule } from './material.module';
import { RegistryComponent } from './pages/registry/registry.component';
import { SpinnerContainerComponent } from './components/spinner-container/spinner-container.component';
import { ClassroomStatusComponent } from './pages/classroom-status/classroom-status.component';
import { UserStatusComponent } from './pages/user-status/user-status.component';
import { BaseChartDirective } from 'ng2-charts';
import { UserListVideoComponent } from './components/user-list-video/user-list-video.component';
import { ResizableModule } from 'angular-resizable-element';
import { LessonVideoComponent } from './pages/lesson-video/lesson-video.component';
import { ChecklistPopupComponent } from './popup/checklist-popup/checklist-popup.component';
import { StationListComponent } from './pages/station-list/station-list.component';
import { StationListPopupComponent } from './pages/station-list/station-list-popup/station-list-popup.component';
import { ChangelogComponent } from './pages/changelog/changelog.component';
import { LessonNotePopupComponent } from './pages/lessons/lesson-note-popup/lesson-note-popup.component';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { CustomerListComponent } from './pages/customer-list/customer-list.component';
import { CourseListComponent } from './pages/course/course-list/course-list.component';
import { CourseComponent } from './pages/course/course.component';
import { CourseVideoComponent } from './pages/course/course-video/course-video.component';
import { CourseAddComponent } from './pages/course/course-add/course-add.component';
import { CoursePopupComponent } from './pages/course/course-popup/course-popup.component';
import { ContentAddComponent } from './pages/course/content-add/content-add.component';
import { AuthorprofileComponent } from './pages/authorprofile/authorprofile.component';
import { TranslationsComponent } from './pages/translations/translations.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { StreamingClosePopupComponent } from './pages/video-session/streaming-close-popup/streaming-close-popup.component';
import { SelectionViewerComponent } from './components/selection-viewer/selection-viewer.component';
import { DashboardPopupComponent } from './pages/dashboard/dashboard-popup/dashboard-popup.component';
import { StartStudentVideoDialogComponent } from './pages/video-session/start-student-video-dialog/start-student-video-dialog.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { StreamingInfoComponent } from './pages/streaming-info/streaming-info.component';
import { ChatComponent } from './components/chat/chat.component';
import { StanzaComponent } from './pages/stanza/stanza.component';
import { IsolaComponent } from './pages/stanza/isola/isola.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MasterComponent } from './pages/master/master.component';
import { MasterAddComponent } from './pages/master/master-add/master-add.component';
import { ErrorDialogComponent } from './pages/stanza/isola/error-dialog/error-dialog.component';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { MasterListComponent } from './pages/master/master-list/master-list.component';
import { UploadsViewerComponent } from './components/uploads-viewer/uploads-viewer.component';
import { ImageHeaderComponent } from './components/image-header/image-header.component';
import { AuthorInfoComponent } from './components/author-info/author-info.component';
import { WallComponent } from './wall/wall.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DialogdispuserlikeComponent } from './wall/extensioncomponent/dialogdispuserlike.component';
import { MasterPopupComponent } from './pages/master/master-popup/master-popup.component';
import { PublicUserConfirmComponent } from './pages/public-user-confirm/public-user-confirm.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ProfilePictureComponent } from './components/profile-picture/profile-picture.component';
import { ShowcaseComponent } from './pages/showcase/showcase.component';
import { MasterAddTeacherComponent } from './pages/master/master-add-teacher/master-add-teacher.component';
import { LinkPreviewComponent } from './wall/extensioncomponent/linkpreview.component';
import { WallPageComponent } from './wall/wall-page/wall-page.component';
import { SurveyCreatorComponent } from './survey/survey-components/survey-creator/survey-creator.component';
import { SurveyPageComponent } from './survey/survey-pages/survey-page/survey-page.component';
import { SurveyComponent } from './survey/survey-components/survey/survey.component';
import { AddSurveyPopUpComponent } from './survey/add-survey-pop-up/add-survey-pop-up.component';
import { SubscriptionHomeComponent } from './ecommerce/subscription-home.component';
import { SubscriptionSellerComponent } from './ecommerce/subscription-seller/subscription-seller.component';
import { SubscriptionBuyerComponent } from './ecommerce/subscription-buyer/subscription-buyer.component';
import { CartPageComponent } from './ecommerce/cart-page/cart-page.component';
import { MasterShowcaseComponent } from './pages/master/master-showcase/master-showcase.component';
import { MasterProfileComponent } from './pages/master/master-profile/master-profile.component';
import { MasterAddPreviewComponent } from './pages/master/master-add-preview/master-add-preview.component';
import { SuccessComponent } from './ecommerce/success/success.component';
import { FailedComponent } from './ecommerce/failed/failed.component';
import { PurchasedComponent } from './ecommerce/purchased/purchased.component';
import { OrdersComponent } from './ecommerce/orders/orders.component';
import { ContentVisualizerComponent } from './popup/content-visualizer/content-visualizer.component';
import { StartOnboardingComponent } from './ecommerce/start-onboarding/start-onboarding.component';
import { ReauthenticateComponent } from './ecommerce/reauthenticate/reauthenticate.component';
import { ReturnstripeComponent } from './ecommerce/returnstripe/returnstripe.component';
import { CourseShowcaseComponent } from './pages/course/course-showcase/course-showcase.component';
import { CourseProfileComponent } from './pages/course/course-profile/course-profile.component';
import { BannerComponent } from './components/banner/banner.component';
import { ContentLockedPopupComponent } from './pages/course/content-locked-popup/content-locked-popup.component';
import { SurveyTemplateDialogComponent } from './survey/survey-template-dialog/survey-template-dialog.component';
import { CourseViewComponent } from './pages/course/course-video/course-view/course-view.component';
import { CourseAnalyticsComponent } from './pages/course/course-analytics/course-analytics.component';
import { SmartEditComponent } from './survey/survey-components/smart-edit/smart-edit.component';
import { PlayerEventsComponent } from './components/player-events/player-events.component';
import { ShowcaseCardComponent } from './components/showcase-card/showcase-card.component';
import { environment } from 'src/environments/environment';
import { DataDeletionComponent } from './pages/data-deletion/data-deletion.component';
import { AddSubscriptionComponent } from './popup/add-subscription/add-subscription.component';
import { UserAccountComponent } from './components/user-account/user-account.component';
import { PackageAddComponent } from './pages/package/package-add/package-add.component';
import { PackageComponent } from './pages/package/package.component';
import { PriceCardComponent } from './components/price-card/price-card.component';
import { QuickViewerComponent } from './popup/quick-viewer/quick-viewer.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { WarningListComponent } from './pages/warning-list/warning-list.component';
import { GenericPopupComponent } from './popup/generic-popup/generic-popup.component';
import { CustomerAddComponent } from './popup/customer-add/customer-add.component';
import { SharePopupComponent } from './popup/share-popup/share-popup.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HashTagsPipe } from './wall/wall.component';
import { faFacebook, faGoogle, faLinkedin, faPinterest, faSkype, faTelegram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { SmilyRecordingComponent } from './pages/smily-recording/smily-recording.component';
import { SmilyDashboardComponent } from './pages/smily-dashboard/smily-dashboard.component';
import { DataWidgetComponent } from './components/data-widget/data-widget.component';
import { CookieService } from 'ngx-cookie-service';
import { FormatTimePipe } from './pipes/formatTimePipe';
import { AddRoomComponent } from './popup/add-room/add-room.component';
import { AddStationComponent } from './popup/add-station/add-station.component';
import { BillingInfoComponent } from './ecommerce/billing-info/billing-info.component';
import { AddUserListPopupComponent } from './popup/add-user-list-popup/add-user-list-popup.component';
import { ImportUsersLessonsComponent } from './popup/import-users-lessons/import-users-lessons.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SearchPipe } from './pipes/searchPipe';
import { PicturePreviewComponent } from './components/picture-preview/picture-preview.component';
import { StationDeactivatePopupComponent } from './pages/stanza/isola/station-deactivate-popup/station-deactivate-popup.component';
import { MatCarouselModule } from '@magloft/material-carousel';
import { ConferenceSessionComponent } from './pages/conference-session/conference-session.component';
import { UserVideoComponent } from './pages/conference-session/user-video/user-video.component';
import { SourceSelectionComponent } from './pages/conference-session/source-selection/source-selection.component';
import { SessionToolbarComponent } from './pages/conference-session/session-toolbar/session-toolbar.component';
import { SessionParticipantsComponent } from './pages/conference-session/session-participants/session-participants.component';
import { ConferencesComponent } from './pages/conferences/conferences.component';
import { AppConferencePopUpComponent } from './pages/conferences/app-conference-pop-up/app-conference-pop-up.component';
import { ShowcaseHeaderComponent } from './components/showcase-header/showcase-header.component';
import { AddSubscriptionLevelComponent } from './popup/add-subscription-level/add-subscription-level.component';
import { SubscriptionLevelComponent } from './pages/subscription-level/subscription-level.component';
import { AddUserComponent } from './pages/user-list/add-user/add-user.component';
import { AddEduplanComponent } from './popup/add-eduplan/add-eduplan.component';
import { EduplanListComponent } from './pages/eduplan-list/eduplan-list.component';
import { EditEduplancontentComponent } from './popup/edit-eduplancontent/edit-eduplancontent.component';
import { EduplanShowcaseComponent } from './pages/eduplan-showcase/eduplan-showcase.component';
import { SafeUrlPipe } from './pipes/safeUrlPipe';
import { ContentScormViewComponent } from './pages/course/course-video/content-scorm-view/content-scorm-view.component';
import { DashboardCustomersComponent } from './pages/dashboard/dashboard-customers/dashboard-customers.component';
import { ConferenceReportComponent } from './pages/conferences/conference-report/conference-report.component';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { InviteUserComponent } from './pages/user-list/invite-user/invite-user.component';
import { ChangeUserInfoComponent } from './pages/user-list/change-user-info/change-user-info.component';
import { CustomerConfirmComponent } from './pages/customer-confirm/customer-confirm.component';
import { DemoPopUpComponent } from './popup/demo-pop-up/demo-pop-up.component';
import { DentaltreyLoginComponent } from './pages/logins/dentaltrey-login/dentaltrey-login.component';
import { WallPopupComponent } from './wall/wall-popup/wall-popup.component';
import { WallBlogPopupComponent } from './wall/wall-blog-popup/wall-blog-popup.component';
import { ConferenceWebinarPopupComponent } from './pages/webinars/conference-webinar-popup/conference-webinar-popup.component';
import { WebinarShowcaseComponent } from './pages/webinars/webinar-showcase/webinar-showcase.component';
import { WebinarSessionComponent } from './pages/webinar-session/webinar-session.component';
import { WebinarListComponent } from './pages/webinars/webinar-list/webinar-list.component';
import { DirectionalChatComponent } from './pages/conference-session/directional-chat/directional-chat.component';
import localeIt from '@angular/common/locales/it';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeRu from '@angular/common/locales/ru';
import localePt from '@angular/common/locales/pt';
import { ConferenceParticipantsComponent } from './pages/conference-session/conference-participants/conference-participants.component';
import { RegisterFormComponent } from './components/register-form/register-form.component';
import { MobileWarningComponent } from './components/mobile-warning/mobile-warning.component';
import { AccessFormComponent } from './components/access-form/access-form.component';
import { ViqirobotLoginComponent } from './pages/logins/viqirobot-login/viqirobot-login.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { SurveyModule } from 'survey-angular-ui';
import { SurveyCreatorModule } from 'survey-creator-angular';
import { SurveyResultPageComponent } from './survey/survey-pages/survey-result-page/survey-result-page.component';
import { SurveyResultsPageComponent } from './survey/survey-pages/survey-results-page/survey-results-page.component';
import { SurveyCreatorPageComponent } from './survey/survey-pages/survey-creator-page/survey-creator-page.component';
import { SurveyTemplateListPageComponent } from './survey/survey-pages/survey-template-list-page/survey-template-list-page.component';
import { PaymentsComponent } from './ecommerce/payments/payments.component';
import { WebinarCustomerComponent } from './pages/webinars/webinar-customer/webinar-customer.component';
import { SunlightLoginComponent } from './pages/logins/sunlight-login/sunlight-login.component';
import { Smartcabinet360LoginComponent } from './pages/logins/smartcabinet360-login/smartcabinet360-login.component';
import { RoomsToolbarComponent } from './pages/conference-session/rooms-toolbar/rooms-toolbar.component';
import { RoomCardComponent } from './pages/conference-session/room-card/room-card.component';
import { RoomPreviewComponent } from './pages/conference-session/room-preview/room-preview.component';
import { FitxpLoginComponent } from './pages/logins/fitxp-login/fitxp-login.component';
import { GammadonnaLoginComponent } from './pages/logins/gammadonna-login/gammadonna-login.component';
import { MobileAppLinkComponent } from './components/mobile-app-link/mobile-app-link.component';
import { EditSubtitlesComponent } from './popup/edit-subtitles/edit-subtitles.component';
import { AudioPlayerEventsComponent } from './components/audio-player-events/audio-player-events.component';
import { RolePipe } from './pipes/rolePipe';
import { PlatformModule } from '@angular/cdk/platform';
import { ContentCardComponent } from './components/content-card/content-card.component';
import { ContentActionButtonsComponent } from './components/content-card/content-action-buttons/content-action-buttons.component';
import { ContentTableComponent } from './components/content-table/content-table.component';
import { HroconsultingLoginComponent } from './pages/logins/hroconsulting-login/hroconsulting-login.component';
import { ShowcaseCardInfoComponent } from './components/showcase-card-info/showcase-card-info.component';
import { CarouselPrevButtonComponent } from './components/carousel/carousel-prev-button/carousel-prev-button.component';
import { CarouselNextButtonComponent } from './components/carousel/carousel-next-button/carousel-next-button.component';
import { ShowcaseCardAuthorInfoComponent } from './components/showcase-card-author-info/showcase-card-author-info.component';
import { TranslationsFormComponent } from './components/translations-form/translations-form.component';
import { JoinedlessonTableComponent } from './components/joinedlesson-table/joinedlesson-table.component';
import { AggregateDataTableComponent } from './components/aggregate-data-table/aggregate-data-table.component';
import { AddClassroomComponent } from './pages/classroom/add-classroom/add-classroom.component';
import { Chart, registerables } from 'chart.js';
import { slk } from "survey-core";
import { ImportUsersLessonsUpdateComponent } from './popup/import-users-lessons/import-users-lessons-update/import-users-lessons-update.component';
import { SurveyAnalyticsComponent } from './survey/survey-pages/survey-analytics/survey-analytics.component';
import { ViewGraphComponent } from './popup/view-graph/view-graph.component';
import { GraphPreviewComponent } from './components/graph-preview/graph-preview.component';
import { HufriedyLoginComponent } from './pages/logins/hufriedy-login/hufriedy-login.component';
import { AixpacademyLoginComponent } from './pages/logins/aixpacademy-login/aixpacademy-login.component';
import { UserTableComponent } from './pages/user-list/user-table/user-table.component';
import { MasterContentTableComponent } from './components/master-content-table/master-content-table.component';
import { MasterContentViewComponent } from './popup/master-content-view/master-content-view.component';
import { MasterContentCardComponent } from './components/master-content-card/master-content-card.component';
import { MasterListUserComponent } from './pages/master/master-list-user/master-list-user.component';
import { CustomerStripePopupComponent } from './popup/customer-stripe-popup/customer-stripe-popup.component';
import { LicarLoginComponent } from './pages/logins/licar-login/licar-login.component';
import { MatTableModule } from '@angular/material/table';
import { ExercisesComponent } from './pages/exercises/exercises.component';
import { ExercisePopUpComponent } from './pages/exercises/exercise-pop-up/exercise-pop-up.component';
import { ExerciseResultsPopupComponent } from './pages/exercises/exercise-results-popup/exercise-results-popup.component';
import { ExerciseDetailsComponent } from './pages/exercises/exercise-details/exercise-details.component';
import { ExerciseService } from './services/exercise.service';
import { RouterModule } from '@angular/router';

const appRoutes: Routes = [ { path: '', component: ExerciseDetailsComponent } ];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ClassroomComponent,
        LessonsComponent,
        ErrorMessageComponent,
        ProfileUserComponent,
        JoinSessionDialogComponent,
        VideoSessionComponent,
        FormatTimePipe,
        UserListComponent,
        ClassroomIdComponent,
        CalendarComponent,
        CalendarUserListComponent,
        CalendarHeaderComponent,
        AppLessonPopUpComponent,
        RegistryComponent,
        SpinnerContainerComponent,
        ClassroomStatusComponent,
        UserStatusComponent,
        UserListVideoComponent,
        LessonVideoComponent,
        ChecklistPopupComponent,
        StationListComponent,
        StationListPopupComponent,
        ChangelogComponent,
        LessonNotePopupComponent,
        CookieBannerComponent,
        ForgotPasswordComponent,
        CustomerListComponent,
        CourseListComponent,
        CourseComponent,
        CourseVideoComponent,
        CourseAddComponent,
        CoursePopupComponent,
        ContentAddComponent,
        UploadsViewerComponent,
        AuthorprofileComponent,
        TranslationsComponent,
        DashboardComponent,
        StreamingClosePopupComponent,
        SelectionViewerComponent,
        DashboardPopupComponent,
        StartStudentVideoDialogComponent,
        StreamingInfoComponent,
        ChatComponent,
        StanzaComponent,
        IsolaComponent,
        MasterComponent,
        MasterAddComponent,
        ErrorDialogComponent,
        MasterListComponent,
        ImageHeaderComponent,
        AuthorInfoComponent,
        WallComponent,
        DialogdispuserlikeComponent,
        MasterPopupComponent,
        PublicUserConfirmComponent,
        SidenavComponent,
        ProfilePictureComponent,
        ShowcaseComponent,
        MasterAddTeacherComponent,
        LinkPreviewComponent,
        WallPageComponent,
        SurveyCreatorComponent,
        SurveyComponent,
        SurveyCreatorPageComponent,
        SurveyPageComponent,
        AddSurveyPopUpComponent,
        SubscriptionHomeComponent,
        SubscriptionSellerComponent,
        SubscriptionBuyerComponent,
        CartPageComponent,
        MasterShowcaseComponent,
        MasterProfileComponent,
        MasterAddPreviewComponent,
        SuccessComponent,
        FailedComponent,
        PurchasedComponent,
        OrdersComponent,
        ContentVisualizerComponent,
        ContentVisualizerComponent,
        StartOnboardingComponent,
        ReauthenticateComponent,
        ReturnstripeComponent,
        CourseShowcaseComponent,
        CourseProfileComponent,
        SurveyResultPageComponent,
        SurveyResultsPageComponent,
        BannerComponent,
        ContentLockedPopupComponent,
        BannerComponent,
        SurveyTemplateDialogComponent,
        CourseViewComponent,
        CourseAnalyticsComponent,
        SmartEditComponent,
        PlayerEventsComponent,
        ShowcaseCardComponent,
        DataDeletionComponent,
        AddSubscriptionComponent,
        UserAccountComponent,
        PackageAddComponent,
        PackageComponent,
        PriceCardComponent,
        QuickViewerComponent,
        WarningListComponent,
        GenericPopupComponent,
        CustomerAddComponent,
        SharePopupComponent,
        HashTagsPipe,
        SmilyRecordingComponent,
        SmilyDashboardComponent,
        DataWidgetComponent,
        AddRoomComponent,
        AddStationComponent,
        BillingInfoComponent,
        AddUserListPopupComponent,
        ImportUsersLessonsComponent,
        SearchPipe,
        StationDeactivatePopupComponent,
        PicturePreviewComponent,
        ConferenceSessionComponent,
        UserVideoComponent,
        SourceSelectionComponent,
        SessionToolbarComponent,
        SessionParticipantsComponent,
        ConferencesComponent,
        AppConferencePopUpComponent,
        ShowcaseHeaderComponent,
        AddSubscriptionLevelComponent,
        SubscriptionLevelComponent,
        AddUserComponent,
        AddEduplanComponent,
        EduplanListComponent,
        EditEduplancontentComponent,
        EduplanShowcaseComponent,
        SafeUrlPipe,
        ContentScormViewComponent,
        DashboardCustomersComponent,
        ConferenceReportComponent,
        InviteUserComponent,
        ChangeUserInfoComponent,
        CustomerConfirmComponent,
        DemoPopUpComponent,
        DentaltreyLoginComponent,
        WallPopupComponent,
        WallBlogPopupComponent,
        ConferenceWebinarPopupComponent,
        WebinarShowcaseComponent,
        WebinarSessionComponent,
        WebinarListComponent,
        DirectionalChatComponent,
        ConferenceParticipantsComponent,
        RegisterFormComponent,
        AccessFormComponent,
        MobileWarningComponent,
        ViqirobotLoginComponent,
        FileInputComponent,
        SurveyTemplateListPageComponent,
        PaymentsComponent,
        WebinarCustomerComponent,
        SunlightLoginComponent,
        Smartcabinet360LoginComponent,
        RoomsToolbarComponent,
        RoomCardComponent,
        RoomPreviewComponent,
        FitxpLoginComponent,
        GammadonnaLoginComponent,
        MobileAppLinkComponent,
        EditSubtitlesComponent,
        AudioPlayerEventsComponent,
        RolePipe,
        ContentCardComponent,
        ContentActionButtonsComponent,
        ContentTableComponent,
        HroconsultingLoginComponent,
        ShowcaseCardInfoComponent,
        CarouselPrevButtonComponent,
        CarouselNextButtonComponent,
        ShowcaseCardAuthorInfoComponent,
        TranslationsFormComponent,
        JoinedlessonTableComponent,
        AggregateDataTableComponent,
        ImportUsersLessonsUpdateComponent,
        AddClassroomComponent,
        SurveyAnalyticsComponent,
        ViewGraphComponent,
        GraphPreviewComponent,
        HufriedyLoginComponent,
        AixpacademyLoginComponent,
        UserTableComponent,
        MasterContentTableComponent,
        MasterContentViewComponent,
        MasterContentCardComponent,
        MasterListUserComponent,
        CustomerStripePopupComponent,
        LicarLoginComponent,
        ExercisesComponent,
        ExercisePopUpComponent,
        ExerciseResultsPopupComponent,
        ExerciseDetailsComponent
    ],
    imports: [
        ClipboardModule,
        BrowserModule,
        CommonModule,
        RouterModule.forRoot(appRoutes),
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        FlexLayoutModule,
        MaterialModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        ResizableModule,
        NgxExtendedPdfViewerModule,
        DragDropModule,
        ScrollingModule,
        SocialLoginModule,
        FontAwesomeModule,
        NgxMatSelectSearchModule,
        MatCarouselModule.forRoot(),
        SurveyModule,
        SurveyCreatorModule,
        PlatformModule,
        BaseChartDirective,
        MatTableModule,
    ],
    providers: [
        //provideCharts(withDefaultRegisterables()),
        ExerciseService,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(environment.googleClientId)
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(environment.facebookAppId)
                    }
                ]
            } as SocialAuthServiceConfig,
        },
        {
            provide: MAT_DATE_LOCALE, useValue: 'it-IT'
        },
        { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
        DatePipe,
        PlayerEventsComponent,
        CookieService,
        RolePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        slk(environment.surveyJsKey);

        Chart.register(...registerables);

        //register data translations of other languages
        registerLocaleData(localeIt, 'it');
        registerLocaleData(localeEs, 'es');
        registerLocaleData(localeFr, 'fr');
        registerLocaleData(localeRu, 'ru');
        registerLocaleData(localePt, 'pt');
        // Add an icon to the library for convenient access in other components
        library.addIcons(faFacebook,
                         faTwitter,
                         faLinkedin,
                         faPinterest,
                         faWhatsapp,
                         faTelegram,
                         faSkype,
                         faGoogle);
      }
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
